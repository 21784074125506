<script>
import {
    defineComponent
} from "@compose"

import {
    ArrayType,
    NumberType,
    ObjectType
} from "@PropTypes"


import useAbstract from "@useAbstract"
import { useRef } from "@useRef"

import AttendeeSelector from "@classes/AttendeeSelector"


export default defineComponent({
    extends: useAbstract,
    components: {
        AttendeeSelector
    },
    model: {
        prop: "value",
        event: "change"
    },
    props: {
        classDetails: ObjectType().isRequired(),
        currentUser: ObjectType(),
        spots: ArrayType(),
        value: NumberType( 0 )
    },
    setup ( props, ctx ) {
        // COMPOSITIONS / INJECTORS
        // ------------------------

        // DATA MODELS / STATE
        // ------------------------
        const {
            get: getWaitlistAttendees,
            ref: waitlistAttendees
        } = useRef( props.value || 1 )



        // HANDLERS
        // ------------------------
        function handleChange ({ target: { value } }) {
            ctx.emit(
                "change",
                Number( getWaitlistAttendees() )
            )
        }



        function handleSubmit () {
            ctx.emit( "submit" )
        }



        // PROVIDERS
        // ------------------------

        return {
            handleChange,
            handleSubmit,
            waitlistAttendees
        }
    },
});
</script>



<template lang="pug">
div(
    v-bind="$vefa.element"
)
    h4(
        class="type:subhed--face:hed"
    ) Waitlist

    p(
        class="type:minor"
    ).
        If any spots open in this class, we send an email to everyone on the Wait List.
        | &nbsp; The first person to hop online &amp; nab the spot(s)&nbsp;wins!

    form(
        o-base="iso:yStart100"
        o-enhanced="aspect:xEq50--view iso:yStart200"
        @submit.prevent="() => ({})"
    )
        dl(
            o-base="rhy:yStart25"
        )
            dt(
                o-base="optics:a11y"
            )
                label First name
            dd
                input(
                    placeholder="*First Name"
                    type="text"
                    required
                    v-bind="$vefa.field"
                    v-model="spots[0].firstName"
                )

            dt(
                o-base="optics:a11y"
            )
                label Last name
            dd
                input(
                    placeholder="*Last name"
                    type="text"
                    required
                    v-bind="$vefa.field"
                    v-model="spots[0].lastName"
                )

            dt(
                o-base="optics:a11y"
            )
                label Email
            dd
                input(
                    placeholder="*Email"
                    type="text"
                    required
                    v-bind="$vefa.field"
                    v-model="spots[0].email"
                )

            dt(
                o-base="optics:a11y"
            )
                label Phone number
            dd
                input(
                    placeholder="*Phone number"
                    type="text"
                    required
                    v-bind="$vefa.field"
                    v-model="spots[0].phone"
                )

    div(
        o-base="iso:yStart100"
        o-enhanced="aspect:xEq50--view iso:yStart300"
    )
        attendee-selector(
            v-model="waitlistAttendees"
            :current-user="currentUser"
            @submit="handleSubmit"
        )
</template>



<vefa>
element:
    class: motif:bright
    o-base: rhy:yStart50 iso:yStart100

waitlistSpots:
    class: type:major color:primary
    o-base: module:fill pad:yEq100
    o-condensed: type--align:middle
    o-enhanced: pad:yEq0 pad:yEnd200

waitlistMessage:
    class: color:shade
    o-enhanced: type:major

field:
    class: motif:bright color:primary--set:placeholder type:minor--case:upper edge:xyEq--set:primary--set:radiiRounded
    o-base: module:balance pad:xEq50 pad:yEq25 aspect:xEq100--pct
    o-enhanced: aspect:xEq50--pct

# form:
#     class: motif:bright
#     o-base: pad:xEq250
#     o-condensed: flex:v pad:xEq0
#     o-enhanced: edge:xyEq--set:primary--set:drop200--set:radiiRounded
#     o-extended: aspect:xAuto
#     # aspect:xEq80--view iso:xEqAuto

# label:
#     class: type:major--weight:700

# formItems:
#     # class: o
#     o-base: flex:v--p:start--s:start rhy:yStart100
#     o-condensed: module:static flex:h--p:start--s:middle grid:h--gap:xEq100--gap:yEq150 rhy:yStart0
#     o-enhanced: pad:xyEq100
</vefa>
