<script>
import {
    defineComponent
} from "@compose"

import {
    NumberType,
    ObjectType
} from "@PropTypes"

import { parseObject } from "@utils/parsers"
const actionMeta = parseObject({ id: "NAV_ROUTES" })

import useAbstract from "@useAbstract"

import ActionControl from "@controls/ActionControl"

export default defineComponent({
    extends: useAbstract,
    components: {
        ActionControl,
    },
    model: {
        prop: "value",
        event: "change"
    },
    props: {
        currentUser: ObjectType(),
        spaces: NumberType( 10 ),
        value: NumberType( 1 )
    },
    setup ( props, ctx ) {
        const signInRoute = `${ actionMeta.LogIn }?next=${ window.location.href }`

        // HANDLERS
        // ------------------------
        function handleChange ({ target: { value } }) {
            ctx.emit(
                "change",
                Number( value )
            )
        }



        function handleSubmit () {
            ctx.emit( "submit" )
        }



        // PROVIDERS
        // ------------------------


        return {
            handleChange,
            handleSubmit,
            signInRoute
        }
    },
});
</script>



<template lang="pug">
form.AttendeeSelector(
    v-bind="$vefa.element"
    @submit.prevent="handleSubmit"
)
    dl(
        v-bind="$vefa.formItems"
        :class="$style.body"
    )
        dt(
            o-base="module:static"
            style="grid-area: label"
        )
            label(
                v-bind="$vefa.label"
                for="regSpots"
            ) Spots:

        dd(
            v-bind="$vefa.selectField"
        )
            select#regSpots(
                v-bind="$vefa.selectControl"
                :class="$style.control"
                :value="value"
                @change="handleChange"
            )
                option(
                    :value="0"
                    disabled
                )
                    slot(
                        name="selector-placeholder"
                    ) Choose Number of Spots

                option(
                    :value="1"
                ) Just Me

                option(
                    :disabled="spaces < 2"
                    :value="2"
                ) 2 people

                option(
                    :disabled="spaces < 3"
                    :value="3"
                ) 3 people

                option(
                    :disabled="spaces < 4"
                    :value="4"
                ) 4 people

                option(
                    disabled
                ) More than 4? See Private Classes for more details!

            expand-icon.LocationChooser-control-icon(
                v-bind="$vefa.icons.postSelect"
                v-if="spaces > 0"
            )

        dt(
            o-base="optics:a11y"
        ) Choose

        dd(
            v-bind="$vefa.formControls"
        )
            slot(
                name="attendee-controls"
                v-bind="{ styles: appendLocalVefa($appStyles.controls.standard, $vefa.formControl) }"
            )
                template(
                    v-if="currentUser.isActive"
                )
                    action-control(
                        type="submit"
                        :apply-vefa-style="appendLocalVefa($appStyles.controls.standard, $vefa.formControl)"
                        :disabled="value == 0"
                    )
                        template(#ActionControl-label) Continue registration

                template(v-else)
                    action-control(
                        :apply-vefa-style="appendLocalVefa($appStyles.controls.standard, $vefa.formControl)"
                        :disabled="value == 0"
                        :route="signInRoute"
                    )
                        template(#ActionControl-label) Sign In / Create an Account

                    p or

                    action-control(
                        type="submit"
                        :apply-vefa-style="appendLocalVefa($appStyles.controls.standard, $vefa.formControl)"
                        :disabled="value == 0"
                    )
                        template(#ActionControl-label) Continue as a guest
</template>



<vefa>
element:
    class: motif:bright
    o-base: pad:xEq250
    o-condensed: flex:v pad:xEq0
    o-enhanced: edge:xyEq--set:primary--set:drop200--set:radiiRounded
    o-extended: aspect:xAuto
    # aspect:xEq80--view iso:xEqAuto

label:
    class: type:major--weight:700 color:secondaryShade

formItems:
    o-base: flex:v--p:start--s:start rhy:yStart100
    o-condensed: module:static flex:h--p:start--s:middle grid:h--gap:xEq100--gap:yEq150 rhy:yStart0
    o-enhanced: pad:xyEq100

selectField:
    class: motif:bright color:secondaryShade edge:xyEq--set:tint
    o-base: module:static flex:h--p:start--s:middle
    style:
        grid-area: select

selectControl:
    class: type:major--weight:700
    o-base: flex:v--p:start--s:start pad:xStart50 pad:xEnd200 pad:yEq75
    o-extended: module:static--self:yMiddle pad:xStart100 pad:xEnd250

formControls:
    class: type:major--align:middle
    o-base: module:static flex:v rhy:yStart100
    o-condensed: flex:h--p:end--s:middle rhy:yStart0 rhy:xStart100
    # o-base="module:balance flex:h--p:start--s:middle iso:xStart300 rhy:xStart150"
    style:
        grid-area: controls

formControl:
    element:
        class: control:secondary
        o-extended: type:major--weight:400 pad:yStart100

    label:
        o-base: module:static
        o-extended: adj:yEnd25

icons:
    postSelect:
        o-base: aspect:xyEq100--text put:xEnd--set:abs iso:xEnd50
        o-extended: aspect:xyEq150--text
</vefa>



<style lang="stylus" module>
.body
    // --columns: min-content ratio(24) 1fr 0 1fr

    +o-condensed()
        --columns: min-content 1fr
        --rows: auto auto
        grid-template-areas: unquote('
            "label select"
            ". controls"
        ')

    +o-enhanced()
        --columns: min-content minmax(200px, 1fr) 0 2fr
        --rows: auto
        grid-template-areas: unquote('
            "label select . controls"
        ')

        // > *
        //     outline: 1px solid red

    +o-extended()
        width: 100%
        --columns: min-content minmax(200px, 1fr) 24px 2fr


.control
    appearance: none
    background: transparent
</style>
