<script>
import {
    computed,
    defineComponent,
    unref
} from "@compose"

import useAbstract from "@useAbstract"

import { usePickupSelector } from "@cart/useCart"

export default defineComponent({
    extends: useAbstract,
    setup ( props, ctx ) {
        // COMPOSITIONS
        // ------------------------
        const {
            updatePickupStatus,
            willPickup
        } = usePickupSelector()



        // DATA MODELS / STATE
        // ------------------------
        const localizeWillPickup = computed({
            get: () => unref( willPickup ),
            set: ( value ) => updatePickupStatus( value ),
        })



        return {
            localizeWillPickup
        }
    }
});
</script>



<template lang="pug">
aside.PickupControl(
    v-bind="$vefa.element"
)
    p(
        v-bind="$vefa.metaText"
    ) Most orders are delivered within 5—7 business days

    label(
        v-bind="$vefa.label"
    )
        input(
            type="checkbox"
            v-model="localizeWillPickup"
        )

        span Avoid Shipping Costs!

    small(
        v-bind="appendLocalVefa($vefa.metaText, $vefa.metaSecondary)"
    ) Pick-up your items at Hipcooks
</template>



<vefa>
element:
    o-base: flex:v--p:start pad:yEq100

label:
    class: type--case:upper color:shade
    o-base: flex:h--p:start--s:base rhy:xStart50 iso:yStart50
    o-condensed: iflex:h

metaText:
    class: type:chrome color:black
    o-base: iso:xEndAuto pad:xStart150
    o-condensed: iso:xEq0

metaSecondary:
    class: color:shade
</vefa>

<style lang="stylus">

</style>
