<script>
import useAbstract from "@useAbstract"

import BackdropMedia from "@media/BackdropMedia"
import ContentCard from "@cards/ContentCard"
import RouteControl from "@controls/RouteControl"

export default {
    name: "ReverseImageCard",
    extends: useAbstract,
    components: {
        BackdropMedia,
        ContentCard,
        RouteControl
    },
    props: {
        ...ContentCard.props
    },
    setup () {
        return {
        }
    }
}
</script>

<template lang="pug">
content-card.ContentCard--reverseMedia(
    v-bind="$props"
    :append-vefa-style="$vefa"
)
    template(#prepend-ContentCard)
        backdrop-media(
            v-if="media"
            :media="media"
        )

    template(#ContentCard-footer="{ localizedRoute }")
        route-control(
            :apply-vefa-style="appendLocalVefa($vefa.control, $appStyles.controls.standard)"
            :href="localizedRoute"
        )
            template(#label) {{ actionLabel || hed }}

</template>


<vefa>
element:
    class: motif:bright edge--set:drop100
    o-base: grid:content
    o-condensed: edge:xyEq0--set:drop0

content:
    o-base: module:static
    o-economy: grid:content--gap:yEq100

hed:
    o-economy: type:subhed--weight:400
    o-extended: type:xhed

body:
    o-condensed: pad:yEnd100
    o-economy: pad:yEq50
    o-enhanced: type:major pad:xEq100
    o-extended: pad:yEnd200 pad:xEq200

# body:
#     o-base:
#     o-condensed: color:primary
#     o-enhanced: type:major pad:xEq100
#     o-extended: type:subhed pad:xEq200

footer:
    o-condensed: put:yEnd--set:abs

control:
    element:
        class: control:primary--set:aliased edge--shift:drop50
        o-economy: type:major
        o-extended: type:subhed pad:yEq100
</vefa>


<style lang="stylus">
.ContentCard--reverseMedia
    --iso-xyEq: ratio(0.25)
    --iso-yStart: ratio(10)
    grid-template-areas: unquote('
            "media media media"
            ". content ."
            ". . ."
        ')

    +o-condensed()
        --iso-yStart: ratio(0.25)
        --iso-yEnd: ratio(17.5)
        grid-template-areas: unquote('
                ". . ."
                ". content ."
                "media media media"
            ')

    .media--backdrop
        grid-area: media

        img
            object-position: center


    .card-footer
        +o-condensed()
            bottom: ratio(-1.5)

        +o-economy()
            bottom: ratio(-2)
</style>
