<script>
import useAbstract from "@useAbstract"

import { locationSplash as heroContext } from "@content/heroBlocks.yaml"

export default {
    name: "LocationHeroBlock",
    extends: useAbstract,
    setup () {
        return {
            heroContext
        }
    }
}
</script>

<template lang="pug">
aside.LocationSplashHeroBlock(
    v-bind="$vefa.element"
)
    img(
        alt="Hipcooks students having a blast"
        v-bind="$vefa.media"
        :src="require(`@res/${ heroContext.media }`)"
    )

    header(
        v-bind="$vefa.header"
    )
        h2(
            v-bind="$vefa.hed"
            v-html="heroContext.hed"
        )
</template>

<style lang="stylus">
.LocationSplash-hero
    img
        max-width: ratio(92)
        max-height: ratio(27.5)
        object-fit: cover
        object-position: top center
</style>

<vefa>
element:
    class: motif:bright
    o-base: flex:v--p:start--s:middle

media:
    class: media:fill

header:
    o-base: module:static flex:v--p:middle--s:middle pad:xyEq50
    o-condensed: pad:xyEq100
    o-economy: pad:xyEq150
    o-extended: pad:xyEq200

hed:
    class: type:major--face:hed--align:middle
    o-condensed: type:subhed--lead:base
    o-economy: type:hed--words:flush
    o-extended: type:display--words:tight
</vefa>
