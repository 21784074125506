<script>
import useAbstract from "@useAbstract"

import BackdropMedia from "@media/BackdropMedia"
import ContentCard from "@cards/ContentCard"
import RouteControl from "@controls/RouteControl"

export default {
    name: "LeadBackdropCard",
    extends: useAbstract,
    components: {
        BackdropMedia,
        ContentCard,
        RouteControl
    },
    props: {
        ...ContentCard.props
    },
}
</script>

<template lang="pug">
content-card.ContentCard--leadBackdrop(
    v-bind="$props"
    :append-vefa-style="$vefa"
)
    template(#prepend-ContentCard)
        backdrop-media(
            v-if="media"
            :apply-vefa-style="$vefa.media"
            :media="media"
        )

    template(#ContentCard-footer="{ localizedRoute }")
        route-control(
            :apply-vefa-style="appendLocalVefa($vefa.control, $appStyles.controls.standard)"
            :href="localizedRoute"
        )
            template(#label) {{ actionLabel || hed }}
</template>


<vefa>
element:
    class: motif:bright edge--set:drop100
    o-base: grid:content
    o-condensed: motif:init edge--set:drop0

content:
    o-condensed: motif:bright edge--set:drop100

hed:
    o-economy: type:subhed--weight:400
    o-extended: type:xhed

body:
    o-condensed: pad:yEnd100
    o-economy: pad:yEq150
    o-enhanced: type:major pad:xEq100
    o-extended: pad:xEq200 pad:yEnd200

control:
    element:
        class: control:secondary--inverse--set:aliased
        o-condensed: module--set:radiiInit
        o-economy: flex:h--p:middle-s:middle type:major
        o-extended: type:subhed pad:yEq100
</vefa>

<style lang="stylus">
.ContentCard--leadBackdrop
    --iso-xyEq: ratio(.25)
    --iso-yStart: ratio(10)
    grid-template-areas: unquote('
        "media media media"
        ". content ."
        ". . ."
    ')

    +o-condensed()
        --iso-xStart: .5fr
        --iso-xEnd: 1fr
        --iso-yEq: ratio(2)
        --iso-yStart: var(--iso-yEq)
        grid-template-areas: unquote('
            ". . media"
            "content content media"
            ". . media"
        ')

    +o-enhanced()
        --columns: .25fr
        --iso-yEq: ratio(4)
        --iso-xStart: .75fr

    +o-extended()
        --columns: 1fr
        --iso-xStart: 3fr
        --iso-xEnd: 6fr

    .media--backdrop
        grid-area: media

        +o-condensed()
            grid-column: 2/-1
            grid-row: 1/-1
            z-index: -1

            img
                object-position: 12vw top

        +o-enhanced()
            img
                object-position: center top


    .ContentCard-content
        +o-condensed()
            --iso-yStart: ratio(.5)

        +o-enhanced()
            --gap-xyEq: 0
            --iso-yStart: ratio(1)

        +o-extended()
            --iso-yStart: ratio(1.5)
</style>
