import { parseTemplateData, parseHtml } from "@utils/parsers"

export const INITIAL_STEP = "overview"

export const REGISTRATION_FLOW_SCHEMA = {
    overview: {
        component: "class-overview",
        isActive: false,
        // INITIAL_STEP
    },
    register: {
        component: "registration-form",
        isActive: false,
    }
}

export const CLASS_DETAILS = {
    ...parseTemplateData({ id: "CLASS_DETAILS" }),
    classDescription: parseHtml( "ClassDescription" ),
    classMenu: parseHtml( "ClassMenu" )
}
