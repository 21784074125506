<script>
import { computed, ref } from "@compose"
import useAbstract from "@useAbstract"

import classCategories from "@content/classCategories.yaml"
import CookingClassContent from "@content/modules/CookingClassCategories.yaml"

import ContentStrata from "@strata/ContentStrata"

export default {
    name: "CookingClassCategories",
    extends: useAbstract,
    components: {
        ContentStrata
    },
    setup () {
        const instance = ref(CookingClassContent)

        const activeClasses = computed(
            () => {
                const {
                    activeClasses
                } = instance.value

                return classCategories
                    .filter(
                        ({ name }) => activeClasses.includes(name)
                    )
                    .map(
                        item => ({
                            ...item,
                            route: `classes/details?filter_select=${ item.route }`
                        })
                    )
            }
        )
        return {
            activeClasses,
            instance
        }
    }
}
</script>

<template lang="pug">
content-strata.ContentStrata--CookingClassCategories(
    v-bind="instance"
    :append-vefa-style="$vefa.append"
    :merge-vefa-style="$vefa.merge"
)
    template(#ContentStrata-body)
        ul.CookingClassCategories-list(
            v-bind="$vefa.list"
        )
            li.CookingClassCategories-list-item(
                    v-bind="$vefa.listItem"
                    v-for="preview in activeClasses"
                    :key="preview.name"
                )
                    a.CookingClassCategories-list-link(
                        v-bind="$vefa.listLink"
                        :href="preview.route"
                    )
                        figure.CookingClassCategories-list-media(
                            class="module:static--set:radiiCircle aspect:ratio--z:above"
                        )
                            img(
                                class="module:bounds fx:shift"
                                v-if="preview.media"
                                :alt="`${ preview.name } image`"
                                :src="require(`@res/${ preview.media }`)"
                            )

                        span(
                            v-bind="$vefa.listLinkLabel"
                        ) {{ preview.name }}
</template>

<vefa>
list:
    class: fx:containRxn
    o-base: flex:h--p:middle--s:middle grid:h--gap:xyEq100 iso:xEqAuto
    o-enhanced: grid:h--gap:xyEq150

listItem:
    class: motif:bright color:primary--h:shift fx:shift

listLink:
    o-base: flex:v--p:start--s:middle rhy:yStart50

listLinkLabel:
    class: type:chrome--weight:700--case:upper
    o-economy: type:base

append:
    header:
        o-base: flex:v--p:start--s:middle rhy:yStart100
        o-economy: rhy:yStart150

merge:
    hed:
        class: type:major--face:hed--weight:400--align:middle--case:upper color:copy
        o-condensed: type:hed
        o-economy: type:display

    lede:
        class: type:minor--weight:400--align:just:xEq color:copy
        o-condensed: type:base
        o-economy: type:major--lead:base
</vefa>

<style lang="stylus">
.ContentStrata--CookingClassCategories
    --columns: minmax(min-content, ratio(63))
    --rows: auto auto
    --iso-xyEq: ratio(2)
    --iso-xEq: minmax(var(--iso-xyEq), 1fr)
    --gap-yEq: ratio(1)

    +o-economy()
        --iso-xyEq: ratio(1.5)
        --gap-yEq: ratio(1.5)

    +o-full()
        --columns: minmax(min-content, ratio(87))


.CookingClassCategories-list
    --columns: repeat(auto-fit, minmax(ratio(5), 1fr))
    grid-auto-flow: row

    +o-full()
        --gap-xyEq: ratio(8)

.CookingClassCategories-list-item
    &:nth-last-child(1)
    &:nth-last-child(2)
        transform: translateX(50%)

        +o-condensed()
            transform: none

.CookingClassCategories-list-media
    img
        transform: scale(1.1)
        transition-duration: .5s

    .CookingClassCategories-list-link:hover > &
        img
            transform: scale(1.2)

</style>
