// defineCustomerManagement
// ------------------------
// IMPORTS
// DATA MODELS / STATE
// PRIVATE FUNCTIONS
// PUBLIC FUNCTIONS
// EXTENDERS
// COMPOSITIONS



// IMPORTS
// ------------------------
import {
    computed,
    unref,
    watch
} from "@compose"

import {
    CC_SCHEMA,
    CUSTOMER_SCHEMA,
    SHIPPING_SCHEMA
} from "@cart/constants"

import { useState } from "@useState"

import { useStorage } from "@services/useLocalStorage"
import { useCartContext } from "./defineCartManagement"


// DATA MODELS / STATE
// ------------------------
export const {
    get: getCC,
    patch: patchCC,
    state: cc,
} = useState( CC_SCHEMA )



export const {
    get: getBilling,
    patch: patchBilling,
    state: billing,
} = useState( CUSTOMER_SCHEMA )



const isBillingInfoCompleted = computed(
    () => {
        const {
            canPayTotalWithDiscount
        } = useCartContext()

        // if you can pay with just discounts/gc,
        // then we only need to check for the condition agreement sign off
        const testCreditCardInfo = unref( canPayTotalWithDiscount )
            ? [ cc.conditionAgreement ]
            : Object.values( cc )

        return testForDefinedValues([
            billing.name,
            billing.email,
            ...testCreditCardInfo,
            ...Object.values( billing.address ),
        ])
    }
)

useStorage({
    keepUpdated: true,
    patch: patchBilling,
    state: billing,
    storageName: "hipcooks-cart-billing",
})

export const {
    get: getShipping,
    patch: patchShipping,
    state: shipping,
} = useState( SHIPPING_SCHEMA )



const isShippingInfoCompleted = computed(
    () => testForDefinedValues([
        shipping.name,
        ...Object.values( shipping.address ),
    ])
)

useStorage({
    keepUpdated: true,
    patch: patchShipping,
    state: shipping,
    storageName: "hipcooks-cart-shipping",
})



// PRIVATE FUNCTIONS
// ------------------------
function copyBillingAddress () {
    return { ...getBilling( "address" ) }
}

function linkAddresses () {
    if ( !getBilling( "shipToBilling" ) ) return // clearStoredBillingInfo()

    patchShipping({
        address: { ...getBilling( "address" ) },
        name: getBilling( "name" ),
    })
    // setStoredShippingInfo(
    // )
}



function testForDefinedValues ( fieldValues ) {
    return fieldValues.every( ( value ) => !!value )
}



// PUBLIC FUNCTIONS
// ------------------------



// EXTENDERS
// ------------------------
watch(
    () => getBilling(),
    linkAddresses,
    {
        deep: true,
        immediate: true
    }
)


// COMPOSITIONS
// ------------------------
export function useCustomerManagement () {
    return {
        billing,
        cc,
        isBillingInfoCompleted,
        isShippingInfoCompleted,
        shipping,
    }
}
