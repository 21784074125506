// defineSyncing
// --------------------
// IMPORTS
// DATA MODELS / STATE
// PRIVATE FUNCTIONS
// PUBLIC FUNCTIONS
// EXTENDERS
// COMPOSITIONS


// IMPORTS
// --------------------
import {
    getData,
    postData
} from "@services/useApi"

// import {
//     getCart,
//     patchCart
// } from "./defineCartManagement"

import {
    mapUiKeysToServerKeys
} from "@utils/parsers"

import { USE_MOCKS, routes } from "@cart/constants"

import { cartPayload, productPayload } from "@cart/mocks"
// DATA MODELS / STATE
// --------------------



// PRIVATE FUNCTIONS
// --------------------
function mapAsUrlParams ( item ) {
    return Object.entries( item )
        .reduce(
            ( payload, [ key, value ]) => {
                payload.append(
                    mapUiKeysToServerKeys( key ),
                    value
                )

                return payload
            },
            new URLSearchParams()
        )
}

function mapAsFormData ( item ) {
    return Object.entries( item )
        .reduce(
            ( payload, [ key, value ]) => {
                payload.append(
                    key,
                    value
                )

                return payload
            },
            new FormData()
        )
}

// PUBLIC FUNCTIONS
// --------------------
export async function readCartMeta () {
    if ( USE_MOCKS ) return cartPayload

    const {
        response
    } = await getData({ route: routes.getCart })

    return response
}



export async function readCartProducts () {
    if ( USE_MOCKS ) return productPayload
    // this is wrapped a bit weird coming from the server
    const {
        response: {
            data: response
        }
    } = await getData({ route: routes.getCartProducts })

    return response
}

export async function submitOrder ( order ) {
    const payload = mapAsFormData( order )

    const {
        error,
        response,
        status
    } = await postData({
        noPackage: true,
        payload,
        route: routes.checkout,
    })

    if ( error ) {
        return {
            data: {
                ...error.response.data
            },
            status: error.response.status
        }
    }
    else {
        return {
            data: response,
            status
        }
    }
}

export async function syncPickupStatus ( item ) {
    const payload = mapAsUrlParams( item )

    const {
        response
    } = await postData({
        payload,
        route: routes.updatePickupStatus,
    })

    return response
}


export async function syncProductDeletion ({ id, type }) {
    const typedRoutes = {
        class: routes.deleteCartClass,
        gc: routes.deleteGiftCertificate,
        store: routes.deleteCartProducts,
    }

    const {
        response
    } = await postData({
        route: typedRoutes[type].replace( "{id}", id ),
    })

    return response
}


export async function syncProductUpdate ( item ) {
    const payload = mapAsUrlParams( item )

    const {
        response
    } = await postData({
        payload,
        route: routes.updateCartProducts,
    })

    return response
}

export async function syncPromoAddition ( giftCode ) {
    const payload = mapAsUrlParams( giftCode )

    const {
        response
    } = await postData({
        payload,
        route: routes.applyCertificate,
    })

    return response
}



export async function syncPromoDeletion ( giftCode ) {
    // payload.append( "gift_code", "E429UWFJ7" )
    const payload = mapAsUrlParams( giftCode )

    const {
        response
    } = await postData({
        payload,
        route: routes.removeCertificate
    })

    return response
}

// EXTENDERS
// --------------------



// COMPOSITIONS
// --------------------
