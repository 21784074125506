import { parseTemplateData } from "@utils/parsers"

export const USE_MOCKS = false
export const INITIAL_STEP = "cart"



const currentUser = parseTemplateData({ id: "CURRENT_USER" })
const cartMeta = parseTemplateData({ id: "CART_META" })


/* eslint-disable vue/sort-keys */
export const CHECKOUT_FLOW_SCHEMA = {
    cart: {
        component: () => import( /* webpackChunkName: "CheckoutCartStep" */ "@cart/CartStep" ),
        isActive: false,
        isBypassed: false,
        nextStepLabel: "Proceed to Checkout"
    },
    payment: {
        component: () => import( /* webpackChunkName: "CheckoutPaymentStep" */ "@cart/PaymentStep" ),
        isActive: false,
        isBypassed: false,
        nextStepLabel: "Proceed to Shipping Address"
    },
    shipping: {
        component: () => import( /* webpackChunkName: "CheckoutShippingStep" */ "@cart/ShippingStep" ),
        isActive: false,
        isBypassed: false,
        nextStepLabel: "Review Order"
    },
    summary: {
        component: () => import( /* webpackChunkName: "CheckoutSummaryStep" */ "@cart/SummaryStep" ),
        isActive: false,
        isBypassed: false,
        nextStepLabel: "Process Order"
    },
    process: {
        component: () => import( /* webpackChunkName: "CheckoutProcessStep" */ "@cart/ProcessStep" ),
        isActive: false,
        isBypassed: false
    },
}
/* eslint-enable */


// /WestLA/cart.json
export const routes = {
    applyCertificate: `/${ cartMeta.CAMPUS }/cart/gift-certificate`,
    cart: `/${ cartMeta.CAMPUS }/cart`,
    checkout: `/${ cartMeta.CAMPUS }/checkout`,
    deleteCartClass: `/${ cartMeta.CAMPUS }/cart/remove/schedule/{id}`,
    deleteCartProducts: `/${ cartMeta.CAMPUS }/cart/remove/product/{id}`,
    deleteGiftCertificate: `/${ cartMeta.CAMPUS }/cart/remove/gift_certificate/{id}`,
    getCart: `/${ cartMeta.CAMPUS }/cart.json`,
    getCartProducts: `/${ cartMeta.CAMPUS }/cart/product-info`,
    myHipcooks: `/${ cartMeta.CAMPUS }/my-hipcooks`,
    removeCertificate: `/${ cartMeta.CAMPUS }/cart/remove/discount`,
    updateCartProducts: `/${ cartMeta.CAMPUS }/cart/update/products`,
    updatePickupStatus: `/${ cartMeta.CAMPUS }/cart/update/pickup`,
    waitlistConfirmation: `/${ cartMeta.CAMPUS }/classes/waitlist`,
}



export const COST_SCHEMAS = {
    /* eslint-disable vue/sort-keys */
    cart: {
        subtotal: 0,
        shipping: 0,
        discounts: 0,
        tax: 0,
    },
    classes: {
        subtotal: 0,
        discounts: 0,
        tax: 0,
    },
    store: {
        subtotal: 0,
        shipping: 0,
        tax: 0,
    },
    /* eslint-enable */
}



export const CC_SCHEMA = {
    conditionAgreement: false,
    cvv: "",
    expirationMonth: "",
    expirationYear: "",
    number: "",
}



export const CUSTOMER_SCHEMA = {
    address: {
        city: "",
        state: "",
        street: "",
        zipcode: "",
    },
    email: currentUser?.email || "",
    name: currentUser?.firstName
        ? `${ currentUser.firstName } ${ currentUser.lastName }`
        : "",
    phone: currentUser?.phone || "",
    shipToBilling: false,
}



export const SHIPPING_SCHEMA = {
    address: {
        city: "",
        state: "",
        street: "",
        zipcode: "",
    },
    name: "",
}



export const PROMO_SCHEMA = {
    appliedGcCode: [],
    appliedGcValue: "",
    giftCertificatesTotal: "",
    giftCerts: {},
}



export const CLASS_SCHEMA = {
    classes: {},
    classesSubtotal: "",
    classesTax: "",
    classesTotal: "",
    totalClassCost: "",
}



export const STORE_SCHEMA = {
    pickup: null,
    productDiscount: "",
    productDiscountPercent: null,
    products: {},
    productShipping: "",
    productSubtotal: "",
    productTax: "",
    productTotal: "",
    // retainedShipping stores the product shipping total
    // when user bounces back and forth between shipping and pickup
    retainedShipping: 0,
    totalTax: "",
}



export const CART_SCHEMA = {
    cartCreated: "",
    cartLastTouch: "",
    cartSubtotal: null,
    cartTotal: "",
    estimatedShipping: 0,
    isSynced: false,
    totalTax: "",
}
