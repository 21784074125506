<script>
import useAbstract from "@useAbstract"

import BackdropMedia from "@media/BackdropMedia"
import ContentCard from "@cards/ContentCard"
import RouteControl from "@controls/RouteControl"

export default {
    name: "ActionCard",
    extends: useAbstract,
    components: {
        BackdropMedia,
        ContentCard,
        RouteControl
    },
    props: {
        ...ContentCard.props
    },
    setup () {
        return {
        }
    }
}
</script>

<template lang="pug">
content-card.ContentCard--action(
    v-bind="$props"
    :append-vefa-style="$vefa"
)
    template(#prepend-ContentCard)
        backdrop-media(
            v-if="media"
            :media="media"
        )

    template(#ContentCard-footer="{ localizedRoute }")
        route-control(
            :apply-vefa-style="appendLocalVefa($vefa.control, $appStyles.controls.standard)"
            :href="localizedRoute"
        )
            template(#label) {{ actionLabel || hed }}
</template>


<vefa>
element:
    class: motif:bright edge--set:drop100
    o-base: grid:content

content:
    o-economy: grid:content--gap:yEq100

hed:
    o-economy: type:subhed--weight:400
    o-extended: type:xhed

body:
    o-condensed: pad:yEnd100
    o-economy: pad:yEq50
    o-enhanced: type:major pad:xEq100
    o-extended: pad:yEnd200
    o-full: pad:yEq50

# footer:
#     o-condensed: put:yEnd--set:abs pad:xEq100

control:
    element:
        class: control:primary--set:aliased edge--shift:drop50
        o-economy: type:major
        o-extended: type:subhed pad:yEq100
</vefa>

<style lang="stylus">
.ContentCard--action
    --iso-xyEq: ratio(.25)
    --iso-yStart: ratio(10)
    grid-template-areas: unquote('
        "media media media"
        ". content ."
        ". . ."
    ')

    +o-condensed()
        --iso-yStart: ratio(9)

    +o-economy()
        --iso-xEq: ratio(.5)
        --iso-yStart: ratio(16)

    +o-extended()
        --iso-yStart: ratio(20)

    .media--backdrop
        grid-area: media

        img
            object-position: top center

    .ContentCard-footer
        +o-condensed()
            bottom: ratio(-1)

        +o-extended()
            bottom: ratio(-1.5)

        +o-full()
            position: static
</style>
