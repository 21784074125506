<script>
import useAbstract from "@useAbstract"

import ContentCard from "@cards/ContentCard"

export default {
    name: "LinkCard",
    extends: useAbstract,
    components: {
        ContentCard,
    },
    props: {
        ...ContentCard.props,
        tag: {
            type: String,
            default: "a"
        },
    },
}
</script>

<template lang="pug">
content-card(
    v-bind="$props"
    :apply-vefa-style="$vefa"
)
    template(#prepend-ContentCard)
        figure(
            v-bind="$vefa.mediaFrame"
        )
            img(
                v-bind="$vefa.media"
                :alt="hed"
                :src="require(`@res/${ media }`)"
            )

    template(#ContendCard-footer)
        slot(name="ContendCard-footer")
</template>

<vefa>
element:
    o-base: module:static flex:v--p:start--s:full

content:
    o-base: module:static--self:xyEq pad:xEq25 pad:yStart50 pad:yEnd75

mediaFrame:
    o-base: optics:xyEq--set:hidden

media:
    class: module:fill fx:shift
</vefa>
