// defineCartManagement
// --------------------
// IMPORTS
// DATA MODELS / STATE
// PRIVATE FUNCTIONS
// PUBLIC FUNCTIONS
// EXTENDERS
// COMPOSITIONS


// IMPORTS
// --------------------
import {
    computed,
    ref,
    onMounted,
    unref,
    watchEffect
} from "@compose"

import hasProperty from "lodash/has"
import { renderCost } from "@cart/utils"

import { useState } from "@useState"

import {
    readCartMeta,
    readCartProducts
} from "./defineSyncing"

import {
    activeClassList,
    classCosts,
    getClassMeta,
    hasClasses,
    patchClassMeta,
    removeClass
} from "./defineClassManagement"

import {
    activeProductList,
    getStoreMeta,
    getShippingCosts,
    hasProducts,
    mergeSyncedProductsIntoCart,
    patchStoreMeta,
    removeProduct,
    storeCosts,
    updateQuantity
} from "./defineStoreManagement"

import {
    activePromoList,
    getPromoMeta,
    hasPromos,
    patchPromoMeta,
    removePromo
} from "./definePromoManagement"

import {
    getBilling,
    getCC,
    getShipping
} from "./defineCustomerManagement"

import {
    CART_SCHEMA,
    COST_SCHEMAS
} from "@cart/constants"



// DATA MODELS / STATE
// --------------------
export const {
    get: getCart,
    patch: patchCart,
    state: cartState
} = useState( CART_SCHEMA )



patchCart({
    billing: getBilling(),
    cc: getCC(),
    instruction: getClassMeta(),
    promos: getPromoMeta(),
    shipping: getShipping(),
    store: getStoreMeta(),
})



const grandTotal = computed(
    () => renderCost( getCart( "cartTotal" ) )
)



const hasActiveCart = computed(
    () => !!unref( totalProducts ).length
)



const hasProductQuantities = computed(
    () => !!unref( totalProducts )
        .reduce(
            ( totalQuantity, { quantity }) => totalQuantity + quantity,
            0
        )
)



const totalCosts = computed(
    () => ({
        discounts: renderCost( Math.abs( getPromoMeta( "appliedGcValue" ) ) ),
        shipping: renderCost( getCart( "estimatedShipping" ) ),
        subtotal: renderCost( getCart( "cartSubtotal" ) ),
        tax: renderCost( getStoreMeta( "totalTax" ) ),
        total: renderCost( getCart( "cartTotal" ) )
    })
)

const canPayTotalWithDiscount = computed(
    () => parseInt( getCart( "cartTotal" ) ) === 0
)


const totalProducts = computed(
    () => ([
        ...unref( activeClassList ),
        ...unref( activeProductList ),
        ...unref( activePromoList )
    ])
)


const bypassShippingRequirement = computed(
    () => !unref( activeProductList ).length || getCart( "store.pickup" )
)


// PRIVATE FUNCTIONS
// --------------------
async function initializeCart () {
    if ( getCart( "isSynced" ) ) return

    const cartMeta = await readCartMeta()
    const products = await readCartProducts()

    syncCartMetaIntoStates({ cartMeta })

    mergeSyncedProductsIntoCart({ products })

    patchCart({ isSynced: true })

    return
}



export function syncCartMetaIntoStates ({ cartMeta }) {
    const loadStates = [
        {
            get: getCart,
            patch: patchCart
        },
        {
            get: getClassMeta,
            patch: patchClassMeta
        },
        {
            get: getPromoMeta,
            patch: patchPromoMeta
        },
        {
            get: getStoreMeta,
            patch: patchStoreMeta
        },
    ]

    loadStates.forEach(
        ( state ) => mergePayloadsIntoState( cartMeta, state )
    )
}



function mergePayloadsIntoState ( payload, { get, patch }) {
    const updatedPayload = Object.fromEntries(
        Object.entries( get() )
            .map(
                ([ key, value ]) => [
                    key,
                    hasProperty( payload, key )
                        ? payload[key]
                        : value
                ]
            )
    )

    return patch( updatedPayload )
}



function setCartSubtotal () {
    const classesSubtotal = parseFloat(
        getClassMeta( "classesSubtotal" )
    )
    const productSubtotal = parseFloat(
        getStoreMeta( "productSubtotal" )
    )
    const cartSubtotal = classesSubtotal + productSubtotal

    patchCart({ cartSubtotal })
}



function setEstimatedShipping () {
    patchCart({
        estimatedShipping: getShippingCosts()
    })
}



// PUBLIC FUNCTIONS
// --------------------
export function deleteFromCart ( flaggedItem ) {
    const removalFns = [ removeProduct, removeClass, removePromo ]

    removalFns.forEach(
        ( fn ) => fn( flaggedItem )
    )

    return
}



// EXTENDERS
// --------------------



// COMPOSITIONS
// --------------------
export function useCartContext () {
    return {
        activeClassList,
        activeProductList,
        activePromoList,
        bypassShippingRequirement,
        canPayTotalWithDiscount,
        cart: getCart(),
        classCosts,
        costSchemas: COST_SCHEMAS,
        deleteFromCart,
        estimatedShipping: computed( () => renderCost( getCart( "estimatedShipping" ) ) ),
        grandTotal,
        hasActiveCart,
        hasClasses,
        hasProductQuantities,
        hasProducts,
        hasPromos,
        storeCosts,
        totalCosts,
        totalProducts,
        updateQuantity,
        willPickup: computed( () => getStoreMeta( "pickup" ) ),
    }
}



export function useCartManagement () {
    onMounted(
        initializeCart
    )

    // E429UWFJ7
    watchEffect(
        setEstimatedShipping
    ) // ?

    watchEffect(
        setCartSubtotal
    )

    return useCartContext()
}
