<script>
import { ref } from "@compose"
import useAbstract from "@useAbstract"

import CARD_CONTENT from "@content/cards.yaml"
import ActionsContent from "@content/modules/LocationActionStrata.yaml"

import ContentStrata from "@strata/ContentStrata"
import ActionCard from "@cards/ActionCard"
import LeadBackdropCard from "@cards/LeadBackdropCard"
import ReverseImageCard from "@cards/ReverseImageCard"

export default {
    name: "LocationActionsStrata",
    extends: useAbstract,
    components: {
        ActionCard,
        ContentStrata,
        LeadBackdropCard,
        ReverseImageCard,
    },
    setup () {
        let cards = ref([])

        cards.value = Object.entries(ActionsContent.activeCards)
            .map(
                ([ key, component ]) => ({
                    component,
                    ...CARD_CONTENT[key]
                })
            )

        return {
            cards
        }
    }
}
</script>

<template lang="pug">
content-strata.ContentStrata--LocationActions(
    class="motif:tint"
    tag="section"
)
    template(#ContentStrata-body)
        ul.LocationActions-list(
            v-bind="$vefa.list"
        )
            template(
                v-for="card, index in cards"
            )
                li.LocationActions-list-item(
                    v-bind="$vefa.listItem"
                    :key="index"
                )
                    component(
                        v-bind="card"
                        :is="card.component"
                    )
</template>

<vefa>
list:
    o-base: flex:v--p:full--s:full rhy:yStart100
    o-condensed: grid:h--gap:xyEq100 rhy:yStart0

listItem:
    o-base: module:balance flex:v--p:full--s:full
</vefa>

<style lang="stylus">
.ContentStrata--LocationActions
    --row: auto
    --iso-xEq: ratio(1)
    --iso-yEnd: ratio(3)
    --iso-yStart: ratio(2)
    grid-template-areas: unquote('
        ". . ."
        ". body ."
        ". . ."
    ')

    +o-extended()
        --columns: minmax(ratio(65), 1fr)
        --gap-yEq: ratio(2)
        --iso-xEq: minmax(ratio(5), 15vw)

.LocationActions-list
    +o-condensed()
        --columns: repeat(2, minmax(calc(50% - 32px), 1fr))
        --rows: auto
        grid-auto-flow: row

    +o-economy()
        --columns: 1.25fr .75fr

    +o-enhanced()
        --columns: 1fr ratio(22)

    +o-extended()
        --columns: 1fr ratio(24)
        --gap-xEq: ratio(5)
        --gap-yEq: ratio(3)


.LocationActions-list-item
    +o-condensed()
        &:first-child
            grid-column: 1 / -1
            z-index: 100
</style>
