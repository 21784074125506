var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.loadedComponent,{tag:"component",attrs:{"append-vefa-style":_vm.appendStyleModule(_vm.$vefa, _vm.$style)},on:{"next":_vm.handleStepAdvance},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var title = ref.title;
var showStepBackControl = ref.showStepBackControl; if ( showStepBackControl === void 0 ) showStepBackControl = false;
var hedStyle = ref.hedStyle; if ( hedStyle === void 0 ) hedStyle = {};
return [_c('header',_vm._b({},'header',_vm.$vefa.header,false),[(showStepBackControl)?_c('button',{on:{"click":_vm.handleStepDecrease}},[_vm._v("<")]):_vm._e(),_c('h1',_vm._b({domProps:{"textContent":_vm._s(title)}},'h1',hedStyle,false))])]}},{key:"pickup-control",fn:function(){return [_c('pickup-control',{attrs:{"append-vefa-style":_vm.$vefa.cartMetaControls}})]},proxy:true},{key:"promo-codes",fn:function(){return [_c('promo-code-editor',{attrs:{"append-vefa-style":_vm.$vefa.cartMetaControls}})]},proxy:true},{key:"grand-total",fn:function(){return [_c('dl',{attrs:{"o-base":"iflex:h--p:start--s:base rhy:xStart150 iso:xStartAuto"}},[_c('dt',{staticClass:"type:major color:shade",attrs:{"o-economy":"type:subhed"}},[_vm._v("Grand Total")]),_c('dd',{staticClass:"type:base color:shade",attrs:{"o-economy":"type:major"}},[_vm._v(_vm._s(_vm.grandTotal))])]),(_vm.hasDoubleChargeWarning)?_c('p',_vm._b({},'p',_vm.$vefa.chargeMessage,false),[_vm._v("You will see two separate charges on your credit card statement.")]):_vm._e()]},proxy:true},{key:"step-control",fn:function(ref){
var stepControlStyle = ref.stepControlStyle;
var isDisabled = ref.isDisabled;
var type = ref.type; if ( type === void 0 ) type = 'button';
var canProceed = ref.canProceed; if ( canProceed === void 0 ) canProceed = true;
return [_c('button',_vm._b({attrs:{"disabled":!_vm.hasProductQuantities || isDisabled,"type":type},on:{"click":function($event){canProceed ? _vm.handleStepAdvance() : function () { return ({}); }}}},'button',stepControlStyle || _vm.$vefa.stepControl,false),[_c('span',[_vm._v(_vm._s(_vm.currentStepMeta.nextStepLabel || "Continue"))])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }