// useStepInterface
// --------------------
// IMPORTS
// DATA MODELS / STATE
// PRIVATE FUNCTIONS
// PUBLIC FUNCTIONS
// EXTENDERS
// COMPOSITIONS

// IMPORTS
// --------------------
import {
    computed,
    onMounted,
    onUnmounted,
    unref,
    watch
} from "@compose"

import { useState } from "@useState"
import { manageEventListener } from "@services/useEvents"
// COMPOSITIONS
// --------------------

export function useStepInterface ({ initialStep, stepSchema }) {
    const {
        get: getStepState,
        patch: patchStepState,
        state: stepState,
    } = useState( stepSchema )

    // DATA MODELS / STATE
    // -------------------
    const totalSteps = Object.keys( stepState ).length

    const currentStep = computed(
        () => Object.entries( stepState )
            .reduce(
                ( currentStep, [ step, state ]) => {
                    if ( state.isActive ) currentStep = step

                    return currentStep
                },
                ""
            )
    )

    const loadedComponent = computed(
        () => ( getStepState( unref( currentStep ) ) || initialStep ).component
    )

    const currentStepMeta = computed(
        () => ( getStepState( unref( currentStep ) ) || initialStep )
    )


    // PRIVATE FUNCTIONS
    // --------------------
    function initializeStep () {
        let hash = window.location.hash

        if ( !hash ) hash = initialStep

        hash = hash.replace( "#", "" )

        Object.keys( stepState )
            .forEach(
                ( step ) => patchStepState({
                    [`${ step }.isActive`]: false
                })
            )

        patchStepState({
            [`${ hash }.isActive`]: true
        })
    }



    function handleSteps ( direction ) {
        return () => {
            let hasAdvanced = false

            const availableSteps = Object.entries({ ...getStepState() }).filter(
                ([ step, state ]) => !state.isBypassed || state.isActive
            )

            const updatedStepState = availableSteps
                .reduce(
                    ( flow, [ step, state ], index, stepFlow ) => {
                        if ( hasAdvanced ) return flow

                        if ( state.isActive && stepFlow[index + direction]) {
                            const [ nextStep ] = stepFlow[index + direction]
                            flow[step].isActive = false
                            flow[nextStep].isActive = true
                            hasAdvanced = true
                        }

                        return flow
                    },
                    { ...getStepState() }
                )

            patchStepState( updatedStepState )
        }
    }



    // PUBLIC FUNCTIONS
    // --------------------
    const handleStepAdvance = handleSteps( 1 )
    const handleStepDecrease = handleSteps( -1 )



    // LIFECYCLE
    // --------------------
    const hashChange = manageEventListener({
        fn: initializeStep,
        onEvent: "hashchange"
    })



    onMounted(
        () => {
            initializeStep()

            hashChange( "add" )
        }
    )



    onUnmounted(
        () => hashChange( "remove" )
    )



    watch(
        currentStep,
        ( step ) => {
            if ( step !== initialStep ) {
                const forceStep = step

                window.location.hash = forceStep

                return
            }
        }
    )


    return {
        currentStep,
        currentStepMeta,
        getStepState,
        handleStepAdvance,
        handleStepDecrease,
        loadedComponent,
        patchStepState,
        stepState,
        totalSteps
    }
}
