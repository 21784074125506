<script>
import useAbstract from "@useAbstract"

import BackdropMedia from "@media/BackdropMedia"
import ContentCard from "@cards/ContentCard"
import RouteControl from "@controls/RouteControl"

export default {
    name: "BackdropCard",
    extends: useAbstract,
    components: {
        BackdropMedia,
        ContentCard,
        RouteControl
    },
    props: {
        ...ContentCard.props
    },
    setup () {
        return {
        }
    }
}
</script>

<template lang="pug">
content-card.ContentCard--backdrop(
    v-bind="$props"
    :append-vefa-style="$vefa"
)
    template(#prepend-ContentCard)
        backdrop-media(
            v-if="media"
            :apply-vefa-style="$vefa.media"
            :media="media"
        )

    template(#ContentCard-footer="{ localizedRoute }")
        route-control(
            :apply-vefa-style="appendLocalVefa($vefa.control, $appStyles.controls.standard)"
            :href="localizedRoute"
        )
            template(#label) {{ actionLabel || hed }}
</template>

<vefa>
element:
    class: motif:bright
    o-base: grid:content

content:
    class: motif:bright
    o-base: module:balance--z:overlay
    o-conomy: grid:content--gap:xyEq0
    style:
        --alpha-bg: .80

header:
    o-economy: optics:a11y

hed:
    class: type:major--weight:700--align:middle color:copy
    o-condensed: type:subhed

body:
    class: type:minor--weight:400--align:just:xEq color:copy
    o-condensed: type:base
    o-economy: module:static type:subhed--lead:up--align:middle pad:xyEq100


footer:
    o-economy: flex:v--p:end--s:start put:yEnd--set:abs

control:
    element:
        class: control:primary--set:aliased edge:xyEq
        # o-economy: type:major
        o-economy: type:subhed pad:yEq100
</vefa>

<style lang="stylus">
.ContentCard--backdrop
    --iso-xyEq: ratio(.25)
    grid-template-areas: unquote('
        ". . ."
        ". content ."
        ". . ."
    ')

    +o-enhanced()
        --iso-xyEq: ratio(.5)

    .media--backdrop
        img
            object-position: top center

    .ContentCard-content
        // background-color: alpha(colors.white, 80%)

        // +o-economy()
        //     --row-template: 1fr auto
        //     grid-template-areas: unquote('
        //         ". . ."
        //         ". body ."
        //         ". footer ."
        //         ". . ."
        //     ')

        +o-economy()
            --columns: 2fr 3fr
            --rows: 1fr
            background-color: transparent
            grid-template-areas: unquote('
                ". . . ."
                ". body footer ."
                ". . . ."
            ')

        +o-enhanced()
            --columns: 1fr 1fr

        +o-extended()
            --columns: 2fr 3fr

    .ContentCard-body
        +o-economy()
            background-color: alpha(colors.white, 80%)
            padding-bottom: ratio(6)

        +o-enhanced()
            padding-bottom: ratio(7)

        +o-extended()
            padding-bottom: ratio(10)

    .ContentCard-footer
        --change-x: ratio(-6)
        --change-y: ratio(-2)

        +o-economy()
            transform: translateX(var(--change-x)) translateY(var(--change-y))

            a
                min-width: ratio(20)

        +o-extended()
            --change-x: ratio(-14)
            --change-y: ratio(-3)
</style>
