<script>
import {
    defineComponent
} from "@compose"

import {
    NumberType,
    StringType
} from "@PropTypes"

import useAbstract from "@useAbstract"


export default defineComponent({
    extends: useAbstract,
    props: {
        cost: NumberType( 0 ),
        date: StringType(),
        spaces: NumberType()
    },
});
</script>



<template lang="pug">
dl.ClassRegistrationMeta(
    v-bind="$vefa.element"
)
    .ClassRegistrationMeta-item--cost(
        style="grid-area: cost"
        v-bind="$vefa.block"
    )
        dt.ClassRegistrationMeta-itemKey(
            v-bind="$vefa.itemKey"
        ) Cost:

        dd(
            o-base="rhy:xStart25 pad:xEnd50"
            o-enhanced="pad:xEnd100"
        )
            span(
                class="type:major color:black"
                o-enhanced="type:subhed"
            ) ${{ cost }}

            span(
                class="type:minor"
                o-enhanced="type:subhed"
            ) per person

    .ClassRegistrationMeta-item--date(
        style="grid-area: date"
        v-bind="$vefa.block"
    )
        dt.ClassRegistrationMeta-itemKey(
            v-bind="$vefa.itemKey"
        ) Date:

        dd(
            class="type:major color:black pad:xStart50"
            o-enhanced="type:subhed pad:xStart100"
        ) {{ date }}

    .ClassDetail-registrationMeta-item--spots(
        class="type:major"
        o-economy="pad:yEq0"
        o-enhanced="flex:h--p:middle"
        style="grid-area: spots"
        v-bind="$vefa.block"
    )
        dt.ClassRegistrationMeta-itemKey(
            v-bind="$vefa.itemKeyVisible"
        ) Available Spots:

        dd(
            v-if="spaces"
            o-base="module:static"
        ) {{ spaces }}

        dd(
            v-else
            o-base="module:static"
        ) Waitlist Only
</template>



<vefa>
element:
    class: color:shade
    o-base: grid:h--gap:yEq100
    o-condensed: iso:xEqAuto pad:yEq100
    o-economy: pad:yEq200
    o-enhanced: grid:h--gap:yEq100

block:
    class: color:shade
    o-base: flex:hAuto--p:start--s:middle pad:yEq50
    o-enhanced: pad:yEq100

itemKey:
    class: optics:a11y

itemKeyVisible:
    o-base: module:static pad:xEnd50
</vefa>



<style lang="stylus">
.ClassRegistrationMeta
    grid-template-columns: max-content max-content
    grid-template-rows: max-content minmax(0, auto)
    grid-template-areas: unquote('
        "cost date"
        "spots spots"
    ')

.ClassRegistrationMeta-item--date
    border-left: 1px solid var(--color-black)
</style>
