// useApi
// --------------------
// IMPORTS
// DATA MODELS / STATE
// PRIVATE FUNCTIONS
// PUBLIC FUNCTIONS
// EXTENDERS
// COMPOSITIONS



// IMPORTS
// --------------------
import Axios from "axios"
Axios.defaults.headers.common["X-CSRF-TOKEN"] = window.csrf_token
Axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"

import {
    mapServerKeysToUiKeys,
    mapUiKeysToServerKeys
} from "@utils/parsers"

import { useState } from "@useState"


// DATA MODELS / STATE
// --------------------
const initialState = {
    /* eslint-disable vue/sort-keys */
    //  error reporting states come last as they over-ride syncing/loading
    isLoading: false,
    isSyncing: false,
    hasError: false,
    hasLoadError: false,
    hasSyncError: false,
    /* eslint-enable */
}

export const {
    patch: patchLoadState,
    state: loadingState
} = useState(
    { ...initialState }
)


//  PRIVATE FUNCTIONS
//  --------------------
function reportError ( error ) {
    patchLoadState({ hasError: true })

    return {
        error,
        success: false,
    }
}


function handleError ( error ) {
    patchLoadState({ isLoading: false })

    return reportError( error )
}


// PUBLIC FUNCTIONS
// --------------------
export async function getData ({ route }) {
    patchLoadState({ isLoading: true })

    try {
        const {
            data: response,
            status
        } = await Axios.get( route )

        patchLoadState({ isLoading: false })

        return {
            response: mapServerKeysToUiKeys( response ),
            status,
            success: true,
        }
    }
    catch ( error ) {
        return handleError( error )
    }
}



export async function postData ({ route, payload = {}, headers = {}, noPackage = false }) {
    patchLoadState({ isSyncing: true })

    try {
        const mappedPayload = noPackage
            ? payload
            : mapUiKeysToServerKeys( payload )

        const packagedPayload = Object.keys( mappedPayload ).length
            ? mappedPayload
            : payload


        const {
            data: response,
            status
        } = await Axios.post(
            route,
            packagedPayload,
            headers
        )

        patchLoadState({ isSyncing: false })

        return {
            response: mapServerKeysToUiKeys( response ),
            status,
            success: true,
        }
    }
    catch ( error ) {
        return handleError( error )
    }
}


export function resetLoadState () {
    return patchLoadState({ ...initialState })
}

// EXTENDERS
// --------------------



// COMPOSITIONS
// --------------------
