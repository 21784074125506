<script>
import {
    defineComponent
} from "@compose"

import {
    ObjectType
} from "@PropTypes"

import { stripHtml } from "@utils/parsers"

import useAbstract from "@useAbstract"
import { useRegistrationContext } from "@classes/defineRegistration"

export default defineComponent({
    extends: useAbstract,
    props: {
        classDetails: ObjectType().isRequired()
    },
    setup ( props ) {
        // COMPOSITIONS / INJECTORS
        // -----------------------
        const {
            currentUser,
            requestedSpots,
            spots,
        } = useRegistrationContext()


        // DATA MODELS / STATE
        // ------------------------


        // PRIVATE FUNCTIONS
        // ------------------------
        function parseClassMenu ({ classMenu }) {
            const liReqEx = /<li>/g

            const menuList = classMenu
                .replace( liReqEx, "" )
                .split( "</li>" )

            menuList.pop()

            return menuList
                .map(
                    ( item ) => stripHtml( item.trim() )
                )
        }


        // PROVIDERS
        // ------------------------

        return {
            classDescription: props.classDetails.classDescription,
            classMenu: parseClassMenu( props.classDetails ),
            currentUser,
            requestedSpots,
            spots,
        }
    },
});
</script>



<template lang="pug">
article(
    v-bind="appendLocalVefa($vefa.element, { class: $style.element })"
)
    slot(
        name="image-carousel"
        v-bind="$appliedVefa"
    )

    header(
        v-bind="$vefa.header"
    )
        h1(
            class="type:major color:shade"
            o-enhanced="module:static--self:yEnd type:subhed pad:yStart200"
            style="grid-area: title"
            v-text="classDetails.title"
        )

    div(
        v-bind="appendLocalVefa($vefa.body, { class: $style.body })"
    )
        div(
            v-bind="$vefa.content"
            v-html="classDescription"
        )

        ul(
            v-bind="$vefa.menu"
        )
            li(
                v-for="item in classMenu"
                v-bind="appendLocalVefa($vefa.menuItem, { class: $style.menuItem })"
            )
                span(
                    v-bind="$vefa.menuItemContent"
                    v-text="item"
                )

        ul(
            v-bind="$vefa.categories"
        )
            li(
                class="text:orange"
                v-bind="appendLocalVefa($vefa.category, { class: $style.category })"
                v-if="classDetails.knifeSkills !== 'N/A'"
                v-html="classDetails.knifeSkills"
            )

            li(
                class="text:green"
                v-bind="appendLocalVefa($vefa.category, { class: $style.category })"
                v-if="classDetails.veggie !== 'N/A'"
                v-html="classDetails.veggie"
            )

            li(
                class="text:gold"
                v-bind="appendLocalVefa($vefa.category, { class: $style.category })"
                v-if="classDetails.wheat !== 'N/A'"
                v-html="classDetails.wheat"
            )

            li(
                class="text:blue"
                v-bind="appendLocalVefa($vefa.category, { class: $style.category })"
                v-if="classDetails.dairy !== 'N/A'"
                v-html="classDetails.dairy"
            )

    slot(
        name="registration-meta"
        v-bind="$appliedVefa"
    )

    slot(
        name="waitlist"
        v-bind="$appliedVefa"
    )

    slot(
        name="controls"
        v-bind="$appliedVefa"
    )
</template>



<vefa>
element:
    o-base: grid:content

header:
    o-base: pad:yStart200 pad:yEnd100
    o-condensed: optics:a11y
    style:
        grid-area: header

carousel:
    style:
        grid-area: carousel

body:
    class: motif:tint
    o-base: module:static flex:h grid:content
    style:
        grid-area: body

content:
    class: motif:bright type:minor--lead:loose
    o-base: pad:xyEq100 rhy:yStart50
    style:
        grid-area: content

menu:
    class: motif:bright type:minor--lead:loose
    o-base: pad:xyEq100 rhy:yStart25
    style:
        grid-area: menu

menuItem:
    o-base: module:static flex:h--p:start--s:start

menuItemContent:
    o-base: module:balance

categories:
    class: motif:bright type:chrome--lead:double
    o-base: pad:xEq250 pad:yEq100
    o-enhanced: pad:xyEq100
    style:
        grid-area: categories

category:
    o-base: flex:h--p:start--s:base

message:

    style:
        grid-area: message

form:
    style:
        grid-area: regForm

waitlist:
    style:
        grid-area: regForm
</vefa>



<style lang="stylus" module>
.element
    --rows: auto auto min-content ratio(1) auto ratio(1) auto
    --iso-xEq: ratio(1)
    --iso-yEnd: ratio(3)
    --iso-yStart: 0
    grid-template-areas: unquote('
        ". . ."
        "carousel carousel carousel"
        ". header ."
        ". regMeta ."
        ". . ."
        ". body ."
        ". . ."
        ". regForm ."
        ". . ."
    ')

    +o-enhanced()
        --rows: auto auto min-content auto ratio(2) auto
        --iso-yStart: ratio(2)
        grid-template-areas: unquote('
            ". . ."
            ". carousel ."
            ". body ."
            ". regMeta ."
            ". message ."
            ". . ."
            ". regForm ."
            ". . ."
        ')

    +o-extended()
        --columns: minmax(ratio(65), 1fr)
        --iso-xEq: minmax(ratio(5), 15vw)

.body
    --rows: auto 2px auto 2px auto
    --iso-xEq: 2px
    --iso-yEq: 2px
    grid-template-areas: unquote('
        ". . ."
        ". content ."
        ". . ."
        ". menu ."
        ". . ."
        ". categories ."
        ". . ."
    ')

    +o-condensed()
        --columns: 2fr 2px 1fr
        --rows: auto 2px auto
        --iso-xEq: 2px
        grid-template-areas: unquote('
            ". . . . ."
            ". content content content ."
            ". . . . ."
            ". menu . categories ."
            ". . . . ."
        ')

    +o-enhanced()
        --iso-yStart: 0

    +o-extended()
        --rows: auto
        --columns: 2.5fr 2px 2fr 2px 200px
        grid-template-areas: unquote('
            ". . . . . . ."
            ". content . menu . categories ."
            ". . . . . . ."
        ')

.menuItem
    &::before
        content: ""
        display: block
        flex: none
        background: url(/static/v2/img/jacks_small.png)
        background-repeat: no-repeat
        height: 18px
        width: 14px
        margin-top: 1px
        margin-right: ratio(0.5)

.category
    &::before
        content: ""
        display: block
        flex: none
        background: currentColor
        border-radius: 50%
        height: ratio(.5)
        width: ratio(.5)
        margin-right ratio(.5)
        float: left
</style>
