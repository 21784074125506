import Vue from "vue"

import "@initCompose"
import { provide } from "@compose"

import appStyles from "@themes/v1/app.yaml"

import attachIcons from "@icons/library"
attachIcons( Vue )



import LocationView from "@views/LocationView"
import VuelessView from "@views/VuelessView"

import CookingClassCategories from "@cross-sell/CookingClassCategories"
import HeroBlock from "@locations/LocationHeroBlock"
import HipcooksCommunity from "@cross-sell/HipcooksCommunity"
import HipcooksCookbook from "@cross-sell/HipcooksCookbook"
import LocationActionStrata from "@locations/LocationActionsStrata"
import CartManager from "@cart/CartManager"
import ClassDetailView from "@classes/ClassDetailView"

Vue.component( "CookingClassCategories", CookingClassCategories )
Vue.component( "HeroBlock", HeroBlock )
Vue.component( "HipcooksCommunity", HipcooksCommunity )
Vue.component( "HipcooksCookbook", HipcooksCookbook )
Vue.component( "LocationActionStrata", LocationActionStrata )
Vue.component( "ClassDetailView", ClassDetailView )
Vue.component( "CartManager", CartManager )


new Vue({
    name: "Hipcooks",
    el: "#app",
    components: {
        LocationView,
        VuelessView
    },
    setup () {
        provide( "appStyles", appStyles )
    }
})
