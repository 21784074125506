<script>
import { computed, provide } from "@compose"
import useAbstract from "@useAbstract"
import useLocations from "@locations/useLocations"
import useNavigation from "@navigation/useNavigation"

import HipcooksBase from "@views/HipcooksBase"
import ContactInfo from "@contacts/ContactInfo"
import RouteList from "@lists/RouteList"
import NewsletterSignup from "@cross-sell/NewsletterSignup"
import QuickLinks from "@navigation/QuickLinks"

const devComponents = [
    // "ClassDetailView"
    // "LocationActionStrata",
    // "HeroBlock",
    // "HipcooksCommunity",
    // "CookingClassCategories",
    // "HipcooksCookbook",
]

export default {
    extends: useAbstract,
    components: {
        ContactInfo,
        HipcooksBase,
        NewsletterSignup,
        QuickLinks,
        RouteList
    },
    setup () {
        provide( "templateType", "location" )

        const currentId = window.location.pathname.split( "/" )[1]

        const {
            currentLocation,
        } = useLocations({ currentId })

        const {
            localizedRoutes: locationNavigation
        } = useNavigation({ includeSchema: [ "locationSpecific" ] })

        const {
            localizedRoutes: colophonNavigationGroup
        } = useNavigation({ includeSchema: [ "cookWithUs", "aboutUs", "helpfulLinks" ] })

        const locationContact = computed(
            () => {
                const {
                    fullName = "",
                    contact = {}
                } = currentLocation.value

                return {
                    fullName,
                    ...contact
                }
            }
        )

        return {
            //  data props
            isDev: ( process.env.NODE_ENV == "development" ),
            colophonNavigationGroup,
            currentLocation,
            locationContact,
            locationNavigation,

            devComponents,
        }
    }
}
</script>

<template lang="pug">
hipcooks-base.LocationView(
    show-action-controls
    show-extended-control
    show-login-control
    :is-operational="currentLocation.active"
)
    //- :class="{ [ $style.inactive ]: !currentLocation.active }"
    template(#main)
        slot(#default)

        template(v-if="isDev")
            component(
                v-for="item in devComponents"
                :is="item"
                :key="item"
            )

        quick-links

        newsletter-signup

    //- MASTHEAD SLOTS
    template(#CurrentLocationBadge-name)
        span {{ currentLocation.name }}

    //- NAVIGATION SLOTS
    template(#SiteNavigation-routes="{ $appliedVefa }")
        route-list(
            tag="ul"
            :apply-vefa-style="$appliedVefa"
            :routes="locationNavigation"
        )

    //- EXTENDED NAVIGATION SLOTS
    template(#ExtendedNavigation-routes="{ $appliedVefa }")
        route-list(
            :apply-vefa-style="$appliedVefa"
            :routes="locationNavigation"
            :title="`In ${ currentLocation.name }`"
        )

    //- COLOPHON SLOTS
    template(#BaseView-SiteColophon-location="{ $appliedVefa }")
        contact-info.SiteColophon-contact(
            v-bind="{ ...locationContact, name: locationContact.fullName }"
            :append-vefa-style="$appliedVefa"
        )

    template(#BaseView-SiteColophon-menuLabel) Change Location

    template(#BaseView-SiteColophon-navigation)
        .SiteColophon-navigationGroup(
            v-bind="$vefa.colophonNavigation.wrapper"
            v-if="colophonNavigationGroup"
        )
            template(
                v-for="(routeMap, index) in colophonNavigationGroup"
            )
                hr(
                    v-bind="$vefa.colophonNavigation.rule"
                    v-if="index !== 0"
                )

                route-list(
                    v-bind="routeMap"
                    :apply-vefa-style="$vefa.colophonNavigation"
                    :key="index"
                )
</template>

<style lang="stylus">
.SiteColophon-navigationGroup
    +over(2000px)
        justify-content: space-around
        padding: 0
</style>

<vefa>
colophonNavigation:
    wrapper:
        o-base: rhy:yStart100
        o-condensed: flex:h--p:grid--s:full rhy:yStart0
        o-enhanced: flex:h--p:space aspect:xFull
        o-extended: flex:h--p:evenly

    rule:
        o-base: edge:hr--set:primary
        o-condensed: module:static edge:vr--set:primary

    element:
        class: type:minor color:primary
        o-base: module:fit--self:yStart flex:vAuto--p:start--s:start grid:v

    hed:
        class: type:base--weight:700--case:upper

    item:
        o-base: pad:yStart50

    link:
        class: type--set:noWrap--h:underline fx:shift
</vefa>
