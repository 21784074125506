<script>
import { Carousel as VCarousel, Slide as VSlide } from "vue-carousel"

export default {
    name: "ImageCarousel",
    props: {
        carouselSpeed: {
            type: Number,
            default: 500
        },
        images: {
            type: Array,
            default: null
        },
        shouldAutoplay: {
            type: Boolean,
            default: true
        },
        shouldLoop: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        VCarousel,
        VSlide
    },
    setup (props) {
        const localCarousel = {
            autoplay: props.shouldAutoplay,
            autoplayTimeout: 5000,
            loop: props.shouldLoop,
            paginationEnabled: false,
            perPage: 1,
            speed: props.carouselSpeed
        }

        return {
            localCarousel
        }
    }
}
</script>

<template lang="pug">
v-carousel(
    v-bind="localCarousel"
    o-base="module:static"
)
    v-slide(
        v-for="image, index in images"
        :key="index"
    )
        img(
            :src="image"
        )
</template>
