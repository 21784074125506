// defineRegistrationFlow
// --------------------
// IMPORTS
// DATA MODELS / STATE
// PRIVATE FUNCTIONS
// PUBLIC FUNCTIONS
// EXTENDERS
// COMPOSITIONS


// IMPORTS
// --------------------
import Axios from "axios"
Axios.defaults.headers.common["X-CSRF-TOKEN"] = window.csrf_token

import { parseTemplateData } from "@utils/parsers"

import { useRef } from "@useRef"
import { useState } from "@useState"

import { routes } from "@cart/constants"

// DATA MODELS / STATE
// --------------------
const {
    ref: requestedSpots
} = useRef( 0 )



const {
    state: currentUser
} = useState(
    parseTemplateData({ id: "CURRENT_USER" })
)



const {
    state: spots
} = useState(
    mapSpotSchema({
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
    })
)



// PRIVATE FUNCTIONS
// --------------------
function mapSpotSchema ( spotSchema ) {
    const initialSpot = Object.entries( currentUser ).reduce(
        ( initialSpot, [ key, value ]) => {
            if ( typeof initialSpot[key] !== "undefined" ) initialSpot[key] = value

            return initialSpot
        },
        { ...spotSchema }
    )

    return [
        initialSpot,
        { ...spotSchema },
        { ...spotSchema },
        { ...spotSchema }
    ]
}


// PUBLIC FUNCTIONS
// --------------------
async function postRegistrationRequest ( fieldProps ) {
    const {
        cancellationPolicyCheck,
        comments,
    } = fieldProps

    if ( !cancellationPolicyCheck ) return

    const payload = new URLSearchParams()

    payload.append( "comments", comments )
    payload.append( "cancellation_policy", cancellationPolicyCheck )
    payload.append( "guests", Math.max( requestedSpots.value - 1, 0 ))

    const signee = spots[0]
    payload.append( "first_name", signee.firstName )
    payload.append( "last_name", signee.lastName )
    payload.append( "email", signee.email )
    payload.append( "phone", signee.phone )

    spots.forEach(
        ( spot, index ) => {
            if ( index === 0 || !spot.firstName ) return
            const guestNumber = index - 1

            payload.append( `guest_first_name_${ guestNumber }`, spot.firstName )
            payload.append( `guest_last_name_${ guestNumber }`, spot.lastName )
            payload.append( `guest_email_${ guestNumber }`, spot.email )
            payload.append( `guest_phone_${ guestNumber }`, spot.phone )
        }
    )

    const { status } = await Axios.post( window.location.href, payload )

    if ( status === 200 ) window.location = routes.cart

    return
}



async function postWaitlistRequest () {
    const payload = new URLSearchParams()

    const signee = spots[0]
    payload.append( "first_name", signee.firstName )
    payload.append( "last_name", signee.lastName )
    payload.append( "email", signee.email )
    payload.append( "phone", signee.phone )
    payload.append( "guests", Math.max( requestedSpots.value - 1, 0 ))

    const { status } = await Axios.post( window.location.href, payload )

    if ( status === 200 ) window.location = routes.waitlistConfirmation

    return
}



// EXTENDERS
// --------------------


// COMPOSITIONS
// --------------------
export function useRegistrationContext () {
    return {
        currentUser,
        requestedSpots,
        spots,
    }
}

export function useRegistration () {
    // COMPOSITIONS
    // --------------------

    return {
        currentUser,
        postRegistrationRequest,
        postWaitlistRequest,
        requestedSpots,
        spots,
    }
}
