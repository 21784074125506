<script>
import { computed } from "@compose"
import useAbstract from "@useAbstract"


export default {
    inheritAttrs: false,
    extends: useAbstract,
    props: {
        activeClass: {
            type: String,
            default: "is-active"
        },
        actionLabel: {
            type: String,
            default: null
        },
        navLabel: {
            type: String,
            default: null
        },
        shortName: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        id: {
            type: String,
            default: null
        },
        href: {
            type: String,
            default: null,
        },
    },
    setup (props) {
        const namedRoute = props.href
            ? false
            : {
                href: props.slug
            }
            // useRouter().$router.resolve({ name: props.id })

        const isNamedRoute = !!namedRoute.length

        const localizedLabel = computed(
            () => {
                const {
                    actionLabel,
                    navLabel,
                    shortName,
                    name
                } = props

                return actionLabel
                    || navLabel
                    || shortName
                    || name
            }
        )

        const boundAttrs = computed(
            () => ({
                activeClass: props.activeClass,
                href: namedRoute.href || props.href,
                // is: isNamedRoute ? null : "a",
                to: isNamedRoute ? { name: props.id } : false
            })
        )

        return {
            boundAttrs,
            localizedLabel,
        }
    }
}
</script>


<template lang="pug">
a(
    v-bind="{ ...$vefa.element, ...boundAttrs }"
    v-on="$listeners"
)
    slot(name="icon")

    span
        slot(name="label") {{ localizedLabel }}
</template>
