export function isRequired () {
    // console.log(this)
    return {
        ...this,
        required: true
    }
}

export function isValidatedBy ( validator = null ) {
    return {
        ...this,
        validator
    }
}

function makePropType ( type, defValue ) {
    return {
        default: defValue,
        isRequired,
        isValidatedBy,
        type,
        validator: null,
    }
}

export function AnyType ( defValue = null ) {
    return {
        default: defValue,
        isRequired,
        isValidatedBy,
        validator: null,
    }
}

export function ArrayType ( defValue = null ) {
    return makePropType(
        Array,
        defValue
    )
}

export function BooleanType ( defValue = false ) {
    return makePropType(
        Boolean,
        defValue
    )
}

export function CollectionType ( defValue = null ) {
    return makePropType(
        [ Object, Array ],
        defValue
    )
}

export function FalseType () {
    return BooleanType()
}

export function TrueType () {
    return BooleanType( true )
}


export function FunctionType ( defValue = null ) {
    return makePropType(
        Function,
        defValue
    )
}

export function NumberType ( defValue = null ) {
    return makePropType(
        Number,
        defValue
    )
}

export function NumericType ( defValue = null ) {
    //  we also accept String as this may come from server
    //  or some 3rd party code that sends the number as a string
    return makePropType(
        [ String, Number ],
        defValue
    )
}

export function ObjectType ( defValue = null ) {
    return makePropType(
        Object,
        defValue
    ).isValidatedBy(
        ( v ) => v
    )
}

export function ScalarType ( defValue = null ) {
    return makePropType(
        [ Boolean, String, Number ],
        defValue
    )
}

export function StringType ( defValue = null ) {
    return makePropType(
        String,
        defValue
    )
}
