// utils
// --------------------
// IMPORTS
// DATA MODELS / STATE
// PRIVATE FUNCTIONS
// PUBLIC FUNCTIONS
// EXTENDERS
// COMPOSITIONS



// IMPORTS
// --------------------



// DATA MODELS / STATE
// --------------------



// PRIVATE FUNCTIONS
// --------------------



// PUBLIC FUNCTIONS
// --------------------
export function renderCost ( v ) {
    const cost = Math.abs( v ).toFixed( 2 )

    return `$${ cost }`
}



// EXTENDERS
// --------------------



// COMPOSITIONS
// --------------------
