export const cartPayload = {
    appliedGcCode: [],
    appliedGcValue: "-0.00",
    cartCreated: null,
    cartLastTouch: "Mon, 15 Mar 2021 15:40:45 GMT",
    cartSubtotal: 290,
    cartTotal: "343.48",
    classes: {
        "1cfec632-5724-459b-8d82-b6a88c412325": {
            comments: "",
            guestInfo: "[]",
            guests: 0,
            id: 18165,
            price: 75,
            signupEmail: "monika@hipcooks.com",
            signupFirstName: "Monika",
            signupLastName: "Reti",
            signupPhone: "4349894862",
            title: "Thai One On",
            totalPrice: 75,
            uuid: "1cfec632-5724-459b-8d82-b6a88c412325"
        }
    },
    classesSubtotal: "225.00",
    classesTotal: "75.00",
    giftCertificatesTotal: "150.00",
    giftCerts: {
        "eec8771e-32fa-4b7b-b79f-43e4161f609f": {
            agreed: true,
            amountToGive: 150,
            campusId: "2",
            campusName: "West LA",
            city: null,
            csrfToken: "ImY3YjZmZTFiNjM0YmQwZmM5MzViM2E5YjdhMTQ1OTgyNmM2NTQ3Yzki.EzFJmw.ikMkuPv5Q1-SrTyYYDFacU-cuLM",
            deliveryMethod: "1",
            message: "me",
            nameOnEnvelope: null,
            recipientEmail: "cobey@wellfire.co",
            recipientName: "me",
            senderEmail: "cobey@wellfire.co",
            senderName: "Jonathan C Potter",
            senderPhone: "4349894862",
            state: null,
            streetAddress: null,
            uuid: "eec8771e-32fa-4b7b-b79f-43e4161f609f",
            zipCode: null
        }
    },
    pickup: false,
    productDiscount: "0.00",
    productDiscountPercent: 0,
    products: {
        7: 2,
        10: 1
    },
    productShipping: "47.30",
    productSubtotal: "65.00",
    productTax: "6.18",
    productTotal: "118.48",
    totalClassCost: "225.00",

}

export const productPayload = [
    {
        name: "Maldon Salt",
        price: "8.00",
        productId: 7,
        quantity: 2,
        shipping: "27.30",
        thumbnail: "https://d13bngi7kivrlk.cloudfront.net/public/product_images/thumbnails/7.jpg",
        total: "16.00"
    },
    {
        name: "Cutting Board",
        price: "49.00",
        productId: 10,
        quantity: 1,
        shipping: "20.00",
        thumbnail: "https://d13bngi7kivrlk.cloudfront.net/public/product_images/thumbnails/10.jpg",
        total: "49.00"
    }
]
