<script>
import {
    defineComponent,
    onMounted
} from "@compose"

import {
    ObjectType
} from "@PropTypes"

import { parseTemplateData } from "@utils/parsers"

import useAbstract from "@useAbstract"
import { useRef } from "@useRef"
import { useRegistrationContext } from "@classes/defineRegistration"

import ActionControl from "@controls/ActionControl"

import classSignupContent from "@content/modules/ClassSignup.yaml"

const cartMeta = parseTemplateData({ id: "CART_META" })

export default defineComponent({
    extends: useAbstract,
    components: {
        ActionControl
    },
    props: {
        classDetails: ObjectType().isRequired()
    },
    setup ( props, ctx ) {
        // COMPOSITIONS / INJECTORS
        // -----------------------
        const {
            currentUser,
            requestedSpots,
            spots,
        } = useRegistrationContext()



        // DATA MODELS / STATE
        // ------------------------
        const {
            get: getCancellationPolicyCheck,
            ref: cancellationPolicyCheck,
        } = useRef(
            false
        )



        const {
            get: getComments,
            ref: comments,
        } = useRef( "" )



        const generalContent = splitContentIntoParagraphs(
            classSignupContent.general.content
        )



        const policyContent = splitContentIntoParagraphs(
            classSignupContent.policies.content
        )



        // PRIVATE FUNCTIONS
        // ------------------------
        function splitContentIntoParagraphs ( contentBlock ) {
            return contentBlock
                .split( "\n\n" )
                .map( ( item ) => `<p>${ item }</p>` )
                .join( "" )
        }



        // HANDLERS
        // ------------------------
        function handleSubmit () {
            ctx.emit(
                "process",
                {
                    cancellationPolicyCheck: getCancellationPolicyCheck() ? "y" : false,
                    comments: getComments(),
                }
            )
        }


        onMounted(
            () => {
                window.scrollTo({
                    behavior: "auto",
                    left: 0,
                    top: 0,
                })
            }
        )



        // PROVIDERS
        // ------------------------

        return {
            ...classSignupContent,
            cancellationPolicyCheck,
            cartMeta,
            comments,
            currentUser,
            generalContent,
            handleSubmit,
            policyContent,
            requestedSpots,
            spots,
        }
    },
});
</script>



<template lang="pug">
form.ClassRegistration(
    v-bind="$vefa.element"
    @submit.prevent="handleSubmit"
)

    slot(
        name="image-carousel"
        v-bind="$appliedVefa"
    )

    header.ClassRegistration-header(
        v-bind="$vefa.header"
    )
        h1(
            class="type:major color:shade"
            o-enhanced="module:static type:subhed pad:yStart100"
            style="grid-area: title"
            v-text="classDetails.title"
        )

    slot(
        name="registration-meta"
        v-bind="$appliedVefa"
    )

    .ClassRegistration-body(
        v-bind="$vefa.body"
    )
        dl(
            o-base="rhy:yStart100"
        )
            div(
                o-base="rhy:yStart100"
            )
                dt(
                    o-base="rhy:yStart25"
                )
                    h2(
                        class="type:major--face:hed--case:upper"
                    ) {{ attendees.title }}

                    p(
                        class="type:chrome"
                    ) {{ attendees.disclaimer }}

                dd
                    dl(
                        o-base="rhy:yStart100"
                    )
                        template(
                            v-for="index in requestedSpots"
                        )
                            div
                                dt(
                                    class="type:major--face:hed--case:upper"
                                ) Spot {{ index }}

                                dd
                                    dl(
                                        o-base="rhy:yStart25"
                                    )
                                        dt(
                                            o-base="optics:a11y"
                                        )
                                            label First name
                                        dd
                                            input(
                                                placeholder="*First Name"
                                                type="text"
                                                required
                                                v-bind="$vefa.field"
                                                v-model="spots[index - 1].firstName"
                                            )

                                        dt(
                                            o-base="optics:a11y"
                                        )
                                            label Last name
                                        dd
                                            input(
                                                placeholder="*Last name"
                                                type="text"
                                                required
                                                v-bind="$vefa.field"
                                                v-model="spots[index - 1].lastName"
                                            )

                                        dt(
                                            o-base="optics:a11y"
                                        )
                                            label Phone number
                                        dd
                                            input(
                                                type="text"
                                                v-bind="$vefa.field"
                                                v-model="spots[index - 1].phone"
                                                :placeholder="`${ (index - 1) === 0 ? '*' : '' }Phone number`"
                                                :required="(index - 1) === 0"
                                            )

                                        dt(
                                            o-base="optics:a11y"
                                        )
                                            label Email
                                        dd
                                            input(
                                                type="text"
                                                v-bind="$vefa.field"
                                                v-model="spots[index - 1].email"
                                                :placeholder="`${ (index - 1) === 0 ? '*' : '' }Email`"
                                                :required="(index - 1) === 0"
                                            )


                                dd(
                                    class="type:chrome iso:yStart200"
                                    v-if="index == 1"
                                ) {{ attendees.receipt }}

            .ClassRegistration-general(
                o-base="flex:h--p:start--s:start grid:h"
                o-enhanced="flex:h--p:start--s:start grid:h"
            )
                dt(
                    class="type:chrome--weight:700"
                    style="grid-area: title"
                    v-text="general.title"
                )

                dd(
                    class="copy type:chrome"
                    o-base="rhy:yStart50"
                    style="grid-area: content"
                )
                    div(
                        v-html="generalContent"
                    )

                    textarea(
                        class="modulee:balance flex:h--p:fill--s:fill"
                        v-bind="$vefa.textarea"
                        v-model="comments"
                    )

            .ClassRegistration-policies
                dt(
                    class="type:chrome--weight:700"
                    v-text="policies.title"
                )
                dd(
                    class="copy type:chrome"
                    o-base="rhy:yStart50"
                    v-html="policyContent"
                )

        dl
            dt(
                o-base="optics:a11y"
            ) Register

            .ClassRegistration-controls(
                o-base="module:static flex:vAuto--p:middle--s:middle iso:yStart200 rhy:yStart100"
                o-condensed="flex:h--p:end rhy:xStart100 rhy:yStart0"
            )
                dd(
                    o-base="module:static"
                )
                    label(
                        class="copy type:chrome--case:upper"
                        o-base="module:static flex:hAuto--p:start--s:middle rhy:xStart50"
                    )
                        input(
                            type="checkbox"
                            v-model="cancellationPolicyCheck"
                        )

                        p I've read and agree to the <a :href="`/${ cartMeta.CAMPUS }/policies`">cancellation policy</a>

                dd(
                    o-base="module:static"
                )
                    action-control(
                        name="submit"
                        type="submit"
                        :apply-vefa-style="appendLocalVefa($appStyles.controls.standard, $vefa.formControl)"
                        :disabled="!cancellationPolicyCheck"
                    )
                        template(#ActionControl-label) Add to Cart
</template>



<vefa>
element:
    o-base: grid:content

header:
    o-base: pad:yStart200 pad:yEnd100
    o-condensed: optics:no
    o-enhanced: optics:yes module:static pad:yEq0
    style:
        grid-area: header

carousel:
    style:
        grid-area: carousel

body:
    o-base: pad:yStart200
    style:
        grid-area: body

field:
    class: motif:bright color:primary--set:placeholder type:minor--case:upper edge:xyEq--set:primary--set:radiiRounded
    o-base: module:balance pad:xEq50 pad:yEq25 aspect:xEq100--pct
    o-enhanced: aspect:xEq50--pct

textarea:
    class: motif:bright color:primary--set:placeholder type:minor--case:upper edge:xyEq--set:primary--set:radiiRounded
    o-base: module:balance pad:xEq50 pad:yEq25

formControl:
    element:
        class: control:secondary
        o-extended: type:major--weight:400 pad:yStart100

    label:
        o-base: module:static
        o-extended: adj:yEnd25
</vefa>



<style lang="stylus">
--set{':'}placeholder
    +contains()
        &::placeholder
            color: currentColor

.ClassRegistration
    --rows: auto auto min-content auto
    // minmax(0, min-content) ratio(1) auto
    --iso-xEq: ratio(1)
    --iso-yEnd: ratio(3)
    --iso-yStart: 0
    grid-template-areas: unquote('
        ". . ."
        "carousel carousel carousel"
        ". header ."
        ". regMeta ."
        ". body ."
    ')

    +o-enhanced()
        --columns: 2fr .5fr 3.5fr
        --rows: ratio(1) auto auto ratio(1) min-content auto
        --iso-yStart: ratio(2)
        grid-template-areas: unquote('
            ". . . . ."
            " . . . carousel ."
            ". header . carousel ."
            ". regMeta . carousel ."
            " . . . carousel ."
            ". body body body ."
        ')

    +o-extended()
        --columns: 2.5fr ratio(2) 2fr
        --iso-xEq: minmax(ratio(5), 15vw)

    .ClassRegistrationMeta
        +o-economy()
            padding: ratio(1) 0 0

        +o-extended()
            margin: 0

.ClassRegistration-general
    --rows: repeat(3, auto)
    // minmax(0, min-content) minmax(0, min-content) ratio(1) minmax(0, min-content)
    grid-template-areas: unquote('
        "title"
        "content"
        "field"
    ')

    +o-economy()
        --columns: 1fr ratio(1) 2fr
        --rows: auto 1fr
        grid-template-areas: unquote('
            "title . field"
            "content . field"
        ')

    +o-enhanced()
        --columns: 2fr ratio(1) 2fr

    textarea
        min-height: 12em

.copy
    a
        color: var(--color-primary)
</style>
