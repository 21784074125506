<script>
import useAbstract from "@useAbstract"
import useNavigation from "@navigation/useNavigation"

import ContentStrata from "@strata/ContentStrata"
import LinkCard from "@cards/LinkCard"

export default {
    name: "QuickLinks",
    extends: useAbstract,
    components: {
        ContentStrata,
        LinkCard,
    },
    setup () {
        const {
            localizedRoutes: quickLinks
        } = useNavigation({ includeSchema: ["quickLinks"] })

        return {
            quickLinks
        }
    }
}
</script>

<template lang="pug">
content-strata.ContentStrata--quickLinks(
    :append-vefa-style="$vefa"
)
    template(#ContentStrata-body)
        nav.QuickLinks-nav(
            v-bind="$vefa.nav"
        )
            link-card.QuickLinks-nav-link(
                v-bind="{ ...route, hed: route.name }"
                v-for="route in quickLinks"
                :append-vefa-style="$vefa.linkCard"
                :key="route.media"
            )

</template>

<vefa>
element:
    class: motif:tint type:subhed--face:hed

nav:
    class: fx:containRxn
    o-base: grid:h--p:middle--gap:xyEq100
    # o-economy: grid:h--p:middle--gap:xyEq300

linkCard:
    element:
        class: motif:bright edge:xyEq0 fx:shift
</vefa>


<style lang="stylus">
.ContentStrata--quickLinks
    --columns: minmax(311px, ratio(61))
    --rows: auto auto
    --iso-xEq: minmax(ratio(2), 1fr)
    --iso-yEq: ratio(2)

    +o-condensed()
        --iso-yEq: ratio(4)

    +o-economy()
        --iso-yEq: ratio(2)

    +o-extended()
        --iso-yEq: ratio(4)

.QuickLinks-nav
    --module-width: minmax(ratio(9), 1fr)
    --columns: repeat(auto-fit, var(--module-width))
    grid-auto-flow: row

    +o-economy()
        --gap-xy: ratio(4)
        --module-width: minmax(ratio(9), ratio(12))

    +o-enhanced()
        --gap-xy: ratio(1.5)
        --module-width: minmax(ratio(7), ratio(8))

    +o-extended()
        --module-width: minmax(ratio(8), 1fr)

.QuickLinks-nav-link
    box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.12),0 1px 2px hsla(0, 0%, 0%, 0.24)

    &:hover
        img
            will-change: transform
            transform: scale(1.35)

    img
        transform: scale(1.25)
        transition-duration: .5s
</style>
