// defineClassManagement
// --------------------
// IMPORTS
// DATA MODELS / STATE
// PRIVATE FUNCTIONS
// PUBLIC FUNCTIONS
// EXTENDERS
// COMPOSITIONS



// IMPORTS
// --------------------
import {
    computed,
    unref
} from "@compose"

import {
    mapServerKeysToUiKeys
} from "@utils/parsers"

import { renderCost } from "@cart/utils"

import { useState } from "@useState"

import {
    syncCartMetaIntoStates
} from "./defineCartManagement"


import {
    getPromoMeta
} from "./definePromoManagement"

import {
    syncProductDeletion
} from "./defineSyncing"

import { CLASS_SCHEMA } from "@cart/constants"

// DATA MODELS / STATE
// --------------------
export const {
    get: getClassMeta,
    getActiveClasses,
    patch: patchClassMeta,
    remove: removeClassMeta,
    ...classMeta
} = useState(
    CLASS_SCHEMA,
    {
        classes: "activeClasses"
    }
)


export const activeClassList = computed(
    () => Object.values( generateCartItems() )
)

export const classCosts = computed(
    () => ({
        discounts: renderCost( getPromoMeta( "appliedGcValue" ) ),
        subtotal: renderCost( getClassMeta( "classesSubtotal" ) ),
        tax: renderCost( getClassMeta( "classesTax" ) ),
        total: renderCost( getClassMeta( "totalClassCost" ) ),
    })
)



export const hasClasses = computed(
    () => !!unref( activeClassList ).length
)

// PRIVATE FUNCTIONS
// --------------------
function generateCartItems () {
    const items = Object.entries( getActiveClasses() )
        .map(
            ([ key, item ]) => ([
                key,
                {
                    ...item,
                    id: item.uuid,
                    media: require( "@res/private-class.jpg" ),
                    name: `${ item.title } for ${ item.guests + 1 }`,
                    quantity: 1,
                    showQuantity: false,
                    showShipping: false,
                    totalPrice: renderCost( item.totalPrice ),
                    type: "class",
                    unitPrice: renderCost( item.price ),
                }
            ])
        )

    return Object.fromEntries( items )
}


// PUBLIC FUNCTIONS
// --------------------
export async function removeClass ( flaggedClass ) {
    // id is a uuid and has non-safe JS characters,
    // so ensure its normalized to match up to object keys
    const normalizedIdent = mapServerKeysToUiKeys( flaggedClass.id )
    const itemKey = `classes.${ normalizedIdent }`
    if ( !classMeta.has( itemKey ) ) return

    const cartMeta = await syncProductDeletion( flaggedClass )

    if ( cartMeta ) {
        syncCartMetaIntoStates({ cartMeta })
        removeClassMeta( itemKey )
    }

    return
}


// EXTENDERS
// --------------------



// COMPOSITIONS
// --------------------
