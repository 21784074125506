<script>
import {
    defineComponent,
    reactive
} from "@compose"

import {
    StringType
} from "@PropTypes"

import {
    INITIAL_STEP,
    REGISTRATION_FLOW_SCHEMA,
    CLASS_DETAILS
} from "./constants"


import useAbstract from "@useAbstract"

import { useRegistration } from "@classes/defineRegistration"
import { useStepInterface } from "@services/useStepInterface"

import AttendeeSelector from "@classes/AttendeeSelector"
import ClassOverview from "@classes/ClassOverview"
import ClassRegistrationMeta from "@classes/ClassRegistrationMeta"
import ImageCarousel from "@media/ImageCarousel"
import RegistrationForm from "@classes/forms/RegistrationForm"
import WaitlistForm from "@classes/forms/WaitlistForm"


export default defineComponent({
    extends: useAbstract,
    components: {
        AttendeeSelector,
        ClassOverview,
        ClassRegistrationMeta,
        ImageCarousel,
        RegistrationForm,
        WaitlistForm
    },
    props: {
        init: StringType( INITIAL_STEP )
    },
    setup ( props ) {
        // COMPOSITIONS / INJECTORS
        // ------------------------
        const {
            currentStep,
            stepState: registrationFlowState,
            handleStepAdvance,
            handleStepDecrease,
            loadedComponent
        } = useStepInterface({
            initialStep: props.init,
            stepSchema: REGISTRATION_FLOW_SCHEMA,
        })



        const {
            currentUser,
            postRegistrationRequest: handleRegistrationRequest,
            postWaitlistRequest: handleWaitlistRequest,
            requestedSpots,
            spots,
        } = useRegistration()



        // DATA MODELS / STATE
        // ------------------------
        const classDetails = reactive( CLASS_DETAILS )

        // @info remove last element as its an empty string
        classDetails.images.pop()



        const carouselProps = {
            images: classDetails.images,
            speed: 500,
        }



        // PROVIDERS
        // ------------------------

        return {
            carouselProps,
            classDetails,
            currentStep,
            currentUser,
            handleRegistrationRequest,
            handleStepAdvance,
            handleStepDecrease,
            handleWaitlistRequest,
            loadedComponent,
            registrationFlowState,
            requestedSpots,
            spots,
        }
    },
});
</script>



<template lang="pug">
component(
    :class-details="classDetails"
    :is="loadedComponent"
    @next="handleStepAdvance"
    @process="handleRegistrationRequest"
)
    template(
        #image-carousel="{ $appliedVefa }"
    )
        image-carousel(
            v-bind="{ ...carouselProps, ...$appliedVefa.carousel }"
        )

    template(
        #registration-meta="{ $appliedVefa }"
    )
        class-registration-meta(
            style="grid-area: regMeta"
            v-bind="classDetails"
        )

    template(
        #waitlist="{ $appliedVefa }"
        v-if="!classDetails.spaces"
    )
        waitlist-form(
            v-model="requestedSpots"
            :append-vefa-style="{ element: $appliedVefa.waitlist }"
            :spots="spots"
            :class-details="classDetails"
            :current-user="currentUser"
            @submit="handleWaitlistRequest"
        )

    template(
        #controls="{ $appliedVefa }"
        v-else
    )
        attendee-selector(
            v-model="requestedSpots"
            :append-vefa-style="{ element: $appliedVefa.form }"
            :spaces="classDetails.spaces"
            :current-user="currentUser"
            @submit="handleStepAdvance"
        )
</template>


<vefa>
</vefa>
