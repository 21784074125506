<script>
import useAbstract from "@useAbstract"

import BackdropMedia from "@media/BackdropMedia"
import ContentCard from "@cards/ContentCard"
import RouteControl from "@controls/RouteControl"

export default {
    name: "ReverseLedeCard",
    extends: useAbstract,
    components: {
        BackdropMedia,
        ContentCard,
        RouteControl
    },
    props: {
        ...ContentCard.props
    },
    setup () {
        return {
        }
    }
}
</script>

<template lang="pug">
content-card.ContentCard--reverseLede(
    v-bind="$props"
    :append-vefa-style="$vefa"
)
    template(#ContentCard-footer="{ localizedRoute }")
        route-control(
            :apply-vefa-style="appendLocalVefa($vefa.control, $appStyles.controls.standard)"
            :href="localizedRoute"
        )
            template(#label) {{ actionLabel || hed }}
</template>

<vefa>
element:
    class: motif:bright

hed:
    class: type:major--weight:700--align:middle color:copy
    o-condensed: type:subhed
    o-economy: type:broadside

content:
    o-extended: grid:content--gap:yEq200

body:
    class: type:minor--weight:400--align:just:xEq color:copy
    o-condensed: type:base
    o-economy: type:subhed--align:middle pad:yStart50 pad:yEnd100
    o-extended: type:subhed--lead:up pad:yStart50

control:
    element:
        class: control:primary--def:aliased edge:xyEq
        o-economy: type:subhed pad:xEq150 pad:yEq100

</vefa>

<style lang="stylus">
.ContentCard--reverseLede
    .ContentCard-content
        +o-condensed()
            --iso-xEq: ratio(0.25)

        +o-economy()
            --rows: auto auto 1fr
            grid-template-areas: unquote('
                    ". . ."
                    ". header ."
                    ". footer ."
                    ". body ."
                    ". . ."
                ')

        +o-enhanced()
            --iso-xEq: ratio(2)
</style>
