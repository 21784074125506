var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.loadedComponent,{tag:"component",attrs:{"class-details":_vm.classDetails},on:{"next":_vm.handleStepAdvance,"process":_vm.handleRegistrationRequest},scopedSlots:_vm._u([{key:"image-carousel",fn:function(ref){
var $appliedVefa = ref.$appliedVefa;
return [_c('image-carousel',_vm._b({},'image-carousel',Object.assign({}, _vm.carouselProps, $appliedVefa.carousel),false))]}},{key:"registration-meta",fn:function(ref){
var $appliedVefa = ref.$appliedVefa;
return [_c('class-registration-meta',_vm._b({staticStyle:{"grid-area":"regMeta"}},'class-registration-meta',_vm.classDetails,false))]}},(!_vm.classDetails.spaces)?{key:"waitlist",fn:function(ref){
var $appliedVefa = ref.$appliedVefa;
return [_c('waitlist-form',{attrs:{"append-vefa-style":{ element: $appliedVefa.waitlist },"spots":_vm.spots,"class-details":_vm.classDetails,"current-user":_vm.currentUser},on:{"submit":_vm.handleWaitlistRequest},model:{value:(_vm.requestedSpots),callback:function ($$v) {_vm.requestedSpots=$$v},expression:"requestedSpots"}})]}}:{key:"controls",fn:function(ref){
var $appliedVefa = ref.$appliedVefa;
return [_c('attendee-selector',{attrs:{"append-vefa-style":{ element: $appliedVefa.form },"spaces":_vm.classDetails.spaces,"current-user":_vm.currentUser},on:{"submit":_vm.handleStepAdvance},model:{value:(_vm.requestedSpots),callback:function ($$v) {_vm.requestedSpots=$$v},expression:"requestedSpots"}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }