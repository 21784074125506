//  useRef (global service)
//  --------------------
//  IMPORTS
//  DATA MODELS / STATE
//  PRIVATE FUNCTIONS
//  PUBLIC FUNCIONS
//  EXTENDERS
//  PROVIDERS
//  COMPOSITIONS
//  CONSTANTS


//  IMPORTS
//  --------------------
import { computed, ref, unref, watch } from "@compose"



//  COMPOSITIONS
//  --------------------
export function useRef ( initialState ) {
    const state = ref( initialState )



    function setState ( newState ) {
        if ( newState !== undefined ) state.value = newState

        return getState()
    }



    // easier use in setup functions
    function getState () {
        return unref( state )
    }



    // setup some syntactic sugar for
    // simple toggle method that gets reused a lot
    function toggleState ( payload = {}) {
        const {
            state = !getState()
        } = payload

        return setState( state )
    }



    const booleanSyntacticSugar = typeof initialState === "boolean"
        ? { toggle: toggleState }
        : {}



    return {
        ...booleanSyntacticSugar,
        get: getState,
        read: computed( () => getState() ),
        ref: state,
        set: setState,
        watch ( fn, options = { deep: true }) {
            return watch(
                state,
                fn,
                options
            )
        }
    }
}
