// defineStoreManagement
// --------------------
// IMPORTS
// DATA MODELS / STATE
// PRIVATE FUNCTIONS
// PUBLIC FUNCTIONS
// EXTENDERS
// COMPOSITIONS



// IMPORTS
// --------------------
import {
    computed,
    unref
} from "@compose"

import { renderCost } from "@cart/utils"

import { useState } from "@useState"

import {
    syncCartMetaIntoStates
} from "./defineCartManagement"

import {
    syncPickupStatus,
    syncProductDeletion,
    syncProductUpdate
} from "./defineSyncing.js"

import { STORE_SCHEMA } from "@cart/constants"



// DATA MODELS / STATE
// --------------------
export const {
    get: getStoreMeta,
    getActiveProducts,
    patch: patchStoreMeta,
    remove: removeStoreMeta,
    ...storeMeta
} = useState(
    STORE_SCHEMA,
    {
        products: "activeProducts",
    }
)



export const activeProductList = computed(
    () => Object.values( generateCartItems() )
)



export const hasProducts = computed(
    () => !!unref( activeProductList ).length
)



export const storeCosts = computed(
    () => ({
        shipping: renderCost( getStoreMeta( "productShipping" ) ),
        subtotal: renderCost( getStoreMeta( "productSubtotal" ) ),
        tax: renderCost( getStoreMeta( "productTax" ) ),
        total: renderCost( getStoreMeta( "productTotal" ) ),
    })
)



// PRIVATE FUNCTIONS
// --------------------
function assignRetainedShipping () {
    const retainedShipping = getStoreMeta( "retainedShipping" )
    const productShipping = getStoreMeta( "productShipping" )

    if ( retainedShipping === 0 || parseInt( productShipping ) !== 0 ) {
        patchStoreMeta({
            retainedShipping: productShipping
        })
    }
}


function generateCartItems () {
    const items = Object.entries( getActiveProducts() )
        .map(
            ([ key, item ]) => ([
                key,
                {
                    ...item,
                    id: item.productId,
                    media: item.thumbnail,
                    shipping: renderCost( item.shipping ),
                    showQuantity: true,
                    type: "store",
                    unitPrice: renderCost( item.price ),
                }
            ])
        )

    return Object.fromEntries( items )
}



// PUBLIC FUNCTIONS
// --------------------
export function getShippingCosts () {
    return getStoreMeta( "pickup" )
        ? 0
        : getStoreMeta( "productShipping" )
}



export function mergeSyncedProductsIntoCart ({ products }) {
    products.forEach(
        ( product ) => {
            const id = product.productId

            patchStoreMeta({
                [`products.${ id }`]: product
            })
        }
    )

    return
}



export async function removeProduct ( flaggedProduct ) {
    const itemKey = `products.${ flaggedProduct.id }`
    if ( !storeMeta.has( itemKey ) ) return

    const cartMeta = await syncProductDeletion( flaggedProduct )

    if ( cartMeta ) {
        syncCartMetaIntoStates({ cartMeta })
        removeStoreMeta( itemKey )
    }

    return
}



export async function updatePickupStatus ( changePickupStatus ) {
    patchStoreMeta({ pickup: changePickupStatus })

    const cartMeta = await syncPickupStatus( getStoreMeta() )

    syncCartMetaIntoStates({ cartMeta })

    return getStoreMeta()
}


export async function updateQuantity ({ id, quantity }) {
    const product = getActiveProducts( id )
    product.quantity = quantity

    const cartMeta = await syncProductUpdate( product )

    syncCartMetaIntoStates({ cartMeta })

    return getStoreMeta()
}

// EXTENDERS / WATCHERS
// --------------------
storeMeta.watch(
    assignRetainedShipping
)


// COMPOSITIONS
// --------------------
export function usePickupSelector () {
    const calculatedShippingCost = computed(
        () => renderCost( getStoreMeta( "productShipping" ) )
    )

    const willPickup = computed(
        () => getStoreMeta( "pickup" )
    )

    return {
        calculatedShippingCost,
        retainedShipping: getStoreMeta( "retainedShipping" ),
        updatePickupStatus,
        willPickup,
    }
}
