// defineCheckoutForms
// ------------------------
// IMPORTS
// DATA MODELS / STATE
// PRIVATE FUNCTIONS
// PUBLIC FUNCTIONS
// EXTENDERS
// COMPOSITIONS



// IMPORTS
// ------------------------
import {
    computed
} from "@compose"

import { stateCodes } from "@services/useStates"

import { useState } from "@useState"

import {
    cc,
    billing,
    shipping
} from "./defineCustomerManagement"

// DATA MODELS / STATE
// ------------------------
/* eslint-disable vue/sort-keys */
const {
    state: billingForm
} = useState({
    name: {
        id: "billingName",
        label: "Billing Name",
        value: computed( () => billing.name ),
    },
    email: {
        id: "billingEmail",
        label: "Billing Email",
        value: computed( () => billing.email ),
    },
    phone: {
        id: "billingPhone",
        label: "Billing Phone Number",
        value: computed( () => billing.phone ),
    },
    streetAddress: {
        id: "billingStreetAddress",
        label: "Street Address",
        value: computed( () => billing.address.street ),
    },
    zip: {
        id: "billingZip",
        label: "Zipcode",
        value: computed( () => billing.address.zipcode ),
    },
    city: {
        id: "billingCity",
        label: "City",
        value: computed( () => billing.address.city ),
    },
    state: {
        id: "billingState",
        label: "State",
        options: stateCodes,
        value: computed( () => billing.address.state ),
    },
    shipToBilling: {
        id: "billingShipToBilling",
        label: "Use same address for Shipping",
        value: computed( () => billing.shipToBilling ),
    }
})

const {
    state: ccForm
} = useState({
    name: {
        id: "cardName",
        label: "Name on Card",
        value: computed( () => billing.name ),
    },
    number: {
        id: "cardNumber",
        label: "Card Number",
        value: computed( () => cc.number ),
    },
    expirationMonth: {
        id: "cardExpirationMonth",
        label: "MM",
        max: 12,
        min: 1,
        value: computed( () => cc.expirationMonth ),
    },
    expirationYear: {
        id: "cardExpirationYear",
        label: "YYYY",
        makeMax: ({ min }) => min + 8,
        min: getCurrentYear(),
        value: computed( () => cc.expirationYear ),
    },
    cvv: {
        id: "cvv",
        label: "Security Code",
        value: computed( () => cc.cvv ),
    },
})


const {
    state: termsAgreementForm
} = useState({
    conditionAgreement: {
        id: "conditionAgreement",
        label: "I agree with terms and conditions",
        value: computed( () => cc.conditionAgreement ),
    },
})


const {
    state: shippingForm
} = useState({
    name: {
        id: "shppingName",
        label: "Name",
        value: computed( () => shipping.name ),
    },
    streetAddress: {
        id: "shippingStreetAddress",
        label: "Street Address",
        value: computed( () => shipping.address.street ),
    },
    zip: {
        id: "shippingZip",
        label: "Zipcode",
        value: computed( () => shipping.address.zipcode ),
    },
    city: {
        id: "shippingCity",
        label: "City",
        value: computed( () => shipping.address.city ),
    },
    state: {
        id: "shippingState",
        label: "State",
        options: stateCodes,
        value: computed( () => shipping.address.state ),
    },
})

// PRIVATE FUNCTIONS
// ------------------------
function getCurrentYear () {
    const fullYear = String( new Date().getFullYear() )

    return parseInt( fullYear ) // .substring( 2 )
}



// PUBLIC FUNCTIONS
// ------------------------



// EXTENDERS
// ------------------------



// COMPOSITIONS
// ------------------------
export function useCheckoutForms () {
    return {
        billingForm,
        ccForm,
        shippingForm,
        termsAgreementForm
    }
}
