<script>
import { ref } from "@compose"
import useAbstract from "@useAbstract"

import CARD_CONTENT from "@content/cards.yaml"
import CommunityContent from "@content/modules/HipcooksCommunity.yaml"

import BackdropCard from "@cards/BackdropCard"
import BackdropMedia from "@media/BackdropMedia"
import ContentStrata from "@strata/ContentStrata"
import ReverseLedeCard from "@cards/ReverseLedeCard"

export default {
    name: "HipcooksCommunity",
    extends: useAbstract,
    components: {
        BackdropCard,
        BackdropMedia,
        ContentStrata,
        ReverseLedeCard
    },
    setup () {
        const instance = ref(CommunityContent)
        const cards = ref([])

        cards.value = Object.entries(CommunityContent.activeCards)
            .map(
                ([ key, component ]) => ({
                    component,
                    ...CARD_CONTENT[key]
                })
            )

        return {
            cards,
            instance
        }
    }
}
</script>

<template lang="pug">
content-strata.ContentStrata--HipcooksCommunity(
    class="motif:bright"
    v-bind="instance"
    :append-vefa-style="$vefa.append"
)
    template(#append-ContentStrata)
        backdrop-media(
            v-bind="instance"
        )

    template(#ContentStrata-body)
        ul.HipcooksCommunity-list(
            v-bind="$vefa.list"
        )
            template(
                v-for="card, index in cards"
            )
                li.HipcooksCommunity-list-item(
                    v-bind="$vefa.listItem"
                    :key="index"
                )
                    component(
                        v-bind="card"
                        :is="card.component"
                    )
</template>

<vefa>
list:
    o-base: flex:v--p:full--s:full rhy:yStart100
    o-condensed: grid:v--gap:xyEq100 rhy:yStart0
    o-economy: flex:v--p:start--s:start grid:v--gap:xyEq200
    o-extended: flex:v--p:middle--s:middle grid:v--gap:xyEq200

listItem:
    o-base: module:balance flex:v--p:full--s:full
    o-economy: flex:v--p:middle

append:
    header:
        o-base: pad:yEnd100 rhy:yStart100
        o-economy: pad:yEnd200 rhy:yStart250
        o-enhanced: pad:yEnd200
        o-extended: pad:yStart100 pad:yEnd300

    hed:
        class: type:major--face:hed--weight:400--align:middle--case:upper color:inverse
        o-condensed: type:hed--set:aliased
        o-economy: type:display
        o-extended: type:broadside
</vefa>

<style lang="stylus">
.ContentStrata--HipcooksCommunity
    --rows: auto auto
    --iso-xyEq: ratio(2)

    +o-economy()
        grid-template-areas: unquote('
            ". . ."
            ". header ."
            ". body ."
            ". . ."
        ')

    +o-enhanced()
        grid-template-areas: unquote('
            ". . ."
            ". header header"
            ". body body"
            ". . ."
        ')

    +o-extended()
        --columns: minmax(min-content, ratio(53))
        --iso-xEnd: minmax(var(--iso-xyEq), 1fr)
        --iso-xStart: ratio(5)
        --iso-yStart: ratio(5)
        --iso-yEnd: ratio(9)

        > .ContentStrata-header
            padding-right: var(--iso-xStart)

    .ContentStrata-header
        align-items: center

.HipcooksCommunity-list
    +o-condensed()
        grid-template-columns: repeat(auto-fit, minmax(calc(50% - 32px), 1fr))
        grid-template-rows: auto
        grid-auto-flow: rows

    +o-economy()
        grid-template-columns: 1fr

    +o-enhanced()
        grid-template-columns: 2fr 1fr

    +o-extended()
        grid-template-columns: minmax(ratio(45), ratio(50)) ratio(23)

    +o-full()
        --gap-xEq: ratio(7)
        --gap-yEq: ratio(3)

.HipcooksCommunity-list-item
    +o-enhanced()
        &:first-child
            align-self: center
            grid-column: 2
            grid-row: 1 / span 3
            z-index: 100
</style>
