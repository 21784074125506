//  helper function to manage event listeners
//  can probably
export function manageEventListener ( props ) {
    const {
        el,
        fn,
        onEvent = "input",
    } = props

    const mountEl = el || window

    return ( hookType ) => {
        mountEl[`${ hookType }EventListener`](
            onEvent,
            fn
        )
    }
}
