//  useState (global service)
//  --------------------
//  IMPORTS
//  DATA MODELS / STATE
//  PRIVATE FUNCTIONS
//  PUBLIC FUNCIONS
//  EXTENDERS
//  PROVIDERS
//  COMPOSITIONS



//  IMPORTS
//  --------------------
import { computed, reactive, del, set, unref, watch } from "@compose"
import hasProperty from "lodash/has"
import setProperty from "lodash/set"
import upperFirst from "lodash/upperFirst"


//  DATA MODELS / STATE
//  --------------------



//  PRIVATE FUNCTIONS
//  --------------------
const isArray = ( item ) => Array.isArray( item )

const isBoolean = ( item ) => typeof item === "boolean"

const isObject = ( item ) => item && typeof item === "object" && !Array.isArray( item )

function createGetters ( schema, state ) {
    const getters = {}

    // no getters, return empty object
    if ( !schema ) return getters

    // on createGetters function call, the map key will evaluate true / false
    // the map will reduce to just one true and one false value
    // we will call the true map value (as a function)
    const typeMap = new Map([
        // array example
        // [ "fields", "records" ]
        // -----
        // adding an array of keys  will produce
        // getFields => getState("fields")
        // getRecords => getState("records")
        [
            isArray( schema ),
            generateGettersFromArray
        ],

        // object example:
        // {
        //     fields: "fieldSchema",
        //     records: "recordSchema"
        // }
        // ---------
        // adding an object of keys to new keys names will produce
        // getFieldSchema => getState("fields")
        // getRecordSchema => getState("records")
        [
            isObject( schema ),
            generateGettersFromObject
        ],

        //  adding a boolean of true will output all state properties
        //  as individual getters
        [
            isBoolean( schema ),
            generateGettersFromBoolean
        ]
    ])

    // call the "true" map value function, which is itself a wrapper
    // around generateGetterFactory, enabling state
    // and schema to be part of scope
    const generatorFn = typeMap.get( true )( state, schema )

    // run state through the generator function,
    // with getters object being filled with requested function list
    return Object.keys( state )
        .reduce(
            generatorFn,
            getters
        )
}



// reducer function to apply requested state key as a get funtion
function generateGetterFactory ( reducer, { key, mapKey, state }) {
    //  assign getState to our local key
    function localGet ( requestKey = null ) {
        if ( requestKey ) {
            const internalPath = `${ key || mapKey }.${ requestKey }`

            return getState( state, internalPath )
        }
        else return getState( state, key || mapKey )
    }

    reducer[`get${ upperFirst( mapKey ) }`] = localGet

    return reducer
}



// wrap test for whether state has the requested key values
// if true, then generate a getter function for that key
// requested schema payload is sent as an array
function generateGettersFromArray ( state, schema ) {
    function setFactory ( getterState, mapKey ) {
        return schema.includes( mapKey )
            ? generateGetterFactory( getterState, { mapKey, state })
            : getterState
    }

    return setFactory
}



// wrap test for whether state has the requested key values
// if true, then generate a getter function for that key
// requested schema payload is sent as an  boolean (of true)
function generateGettersFromBoolean ( state, schema ) {
    function setFactory ( getterState, mapKey ) {
        return generateGetterFactory( getterState, { mapKey, state })
    }

    return setFactory
}



function generateGettersFromObject ( state, schema ) {
    function setFactory ( getterState, key ) {
        return Object.keys( schema ).includes( key )
            ? generateGetterFactory( getterState, { key, mapKey: schema[key], state })
            : getterState
    }

    return setFactory
}



function getState ( state, keyPath ) {
    if ( !keyPath ) return unref( state )

    const keys = keyPath.split
        ? keyPath.split( "." )
        : [ keyPath ]

    const key = keys.shift()

    if ( !state[key] || !keys.length ) {
        return unref(
            state[key]
        )
    }

    return getState(
        state[key],
        keys.join( "." ) // think about this maybe needing to happen in set / remove?
    )
}


function hasStateProperty ( state, key ) {
    return hasProperty( state, key )
}



function patchState ( state, payload ) {
    // instead of `Object.assign(this.someObject, { a: 1, b: 2 })`
    // this.someObject = Object.assign({}, this.someObject, { a: 1, b: 2 })
    Object.entries( payload ).forEach(
        ([ key, value ]) => setState( state, key, value )
    )


    return
}



function removeState ( state, schema ) {
    const typeMap = new Map([
        [
            isArray( schema ),
            {
                generatorFn: removeStateByKey,
                packageSchema ( schema ) {
                    return schema.map(
                        ( item ) => `${ item }`
                    )
                }
            }
        ],
        [
            isObject( schema ),
            {
                generatorFn: removeStateByKeyedObject,
                packageSchema ( schema ) { return schema }
            }

        ],
        [
            !isArray( schema ) && !isObject( schema ) && schema !== undefined,
            {
                generatorFn: removeStateByKey,
                packageSchema ( schema ) {
                    return [ `${ schema }` ]
                }
            }

        ],
        [
            schema instanceof Function,
            {
                generatorFn: removeStateByFunction,
                packageSchema ( schema ) { return schema }
            }
        ],
        [
            schema === undefined,
            {
                generatorFn: removeStateByKey,
                packageSchema () {
                    return Object.keys( state )
                        .map(
                            ( item ) => `${ item }`
                        )
                }
            }
        ]
    ])

    const {
        generatorFn,
        packageSchema
    } = typeMap.get( true )

    generatorFn( state, packageSchema( schema ) )
}


function removeStateByFunction ( state, schema ) {
    console.log( "removeStateByFuction needs to be figured out some more" )

    return () => ({})
}



function removeStateByKeyedObject () {
    console.error( "Do not supply delete state function with an object" )
    // console.log( "removeStateByKeyedObject" )
}



function removeStateByKey ( state, schemaKeys ) {
    schemaKeys.forEach(
        ( schemaKey ) => {
            const nestedKeys = schemaKey.split( "." )

            const removalKey = nestedKeys.shift()

            if ( !state[removalKey]) {
                return
            }

            if ( !nestedKeys.length ) {
                del( state, removalKey )

                return
            }

            let passedState = state[removalKey]

            if ( isArray( passedState ) ) {
                const removalIndex = nestedKeys.shift()

                passedState = state[removalKey][removalIndex]
            }

            removeStateByKey(
                passedState,
                nestedKeys
            )
        }
    )

    return
}



function setState ( state, keyPath, value ) {
    // hmmm... does this work with complex keys? can we make it recursive?
    if ( !hasProperty( state, keyPath ) ) {
        const constructorObj = {}

        setProperty( constructorObj, keyPath, value )

        const constructorKey = Object.keys( constructorObj )[0]

        set( state, constructorKey, {})
    }

    setProperty( state, keyPath, value )

    return getState( state, keyPath )

    //  this doesn't work with applying a reactive object...
    // it removes that link between reactive states
    // const keys = keyPath.split
    //     ? keyPath.split( "." )
    //     : [ keyPath ]

    // const key = keys.shift()


    // if ( !state[key]) {
    //     set( state, key, {})
    // }

    // if ( !keys.length ) {
    //     if ( value && isObject( value ) ) {
    //         state[key] = { ...state[key], ...value }
    //         if ( key === "syncedRecords" ) console.log( key, value, state )
    //     }
    //     else {
    //         state[key] = value
    //     }

    //     return getState( state, key )
    // }

    // setState( state[key], keys, value )
}



//  COMPOSITIONS
//  --------------------
export function useState ( stateSchema, gettersSchema = false ) {
    if ( !stateSchema ) console.error( "Please provide state schema to useState" )



    //  DATA MODELS / STATE
    //  --------------------
    const state = reactive( stateSchema )



    //  PUBLIC FUNCIONS
    //  --------------------
    function getLocalState ( key ) {
        return getState( state, key )
    }



    function hasLocalProperty ( key ) {
        try {
            if ( key === undefined ) throw "Testing for state existance must supply a key"

            return hasStateProperty( state, key )
        }
        catch ( error ) {
            console.error( error )
        }
    }



    function patchLocalState ( payload ) {
        try {
            if ( payload === undefined ) throw "Patching state must supply an object payload"

            patchState( state, payload )

            return getState( state )
        }
        catch ( error ) {
            console.error( error )
        }
    }

    function removeLocalState ( payload ) {
        try {
            if ( payload === undefined ) throw "Removing state must supply an object payload"

            removeState( state, payload )

            return getState( state )
        }
        catch ( error ) {
            console.error( error )
        }
    }



    function setLocalState ( key, value ) {
        try {
            if ( key === undefined || value === undefined ) throw "Setting state must supply a key and value"

            setState( state, key, value )

            return getState( key )
        }
        catch ( error ) {
            console.error( error )
        }
    }



    //  PROVIDERS
    //  --------------------
    return {
        // ,,,create defined? hasRecords, hasState, etc?
        ...createGetters( gettersSchema, state ),
        clear: () => removeLocalState(),
        get: getLocalState,
        has: hasLocalProperty,
        patch: patchLocalState,
        read: computed( () => state ),
        remove: removeLocalState,
        set: setLocalState,
        state,
        watch ( fn, options = { deep: true }) {
            return watch(
                state,
                fn,
                options
            )
        }
    }
}
