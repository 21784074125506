// definePromoManagement
// --------------------
// IMPORTS
// DATA MODELS / STATE
// PRIVATE FUNCTIONS
// PUBLIC FUNCTIONS
// EXTENDERS
// COMPOSITIONS

// test GC: E429UWFJ7


// IMPORTS
// --------------------
import {
    computed,
    unref
} from "@compose"

import {
    mapServerKeysToUiKeys
} from "@utils/parsers"

import { renderCost } from "@cart/utils"

import { useState } from "@useState"

import {
    syncCartMetaIntoStates
} from "./defineCartManagement"

import {
    syncProductDeletion,
    syncPromoAddition,
    syncPromoDeletion
} from "./defineSyncing"

import { PROMO_SCHEMA } from "@cart/constants"


// DATA MODELS / STATE
// --------------------
// export const promosState = useState( PROMO_SCHEMA )

export const {
    get: getPromoMeta,
    getActivePromos,
    getAppliedCodes,
    patch: patchPromoMeta,
    remove: removePromoMeta,
    ...promoMeta
} = useState(
    PROMO_SCHEMA,
    {
        appliedGcCode: "appliedCodes",
        giftCerts: "activePromos",
    }
)


export const activePromoList = computed(
    () => Object.values( generateCartItems() )
)

export const hasPromos = computed(
    () => !!unref( activePromoList ).length
)


// PRIVATE FUNCTIONS
// --------------------
function generateCartItems () {
    const items = Object.entries( getActivePromos() )
        .map(
            ([ key, item ]) => ([
                key,
                {
                    ...item,
                    id: item.uuid,
                    media: require( `@res/gift-certificate.jpg` ),
                    name: `Gift Certificate for ${ item.recipientName }`,
                    quantity: 1,
                    showQuantity: false,
                    showShipping: false,
                    type: "gc",
                    unitPrice: renderCost( item.amountToGive ),
                }
            ])
        )

    return Object.fromEntries( items )
}



async function applyPromoToOrder ({ promoIdent }) {
    const appliedPromos = getPromoMeta( "appliedGcCode" )
    const currentDiscount = getPromoMeta( "appliedGcValue" )

    const promoAlreadyApplied = !!appliedPromos
        .find(
            ( item ) => item === promoIdent
        )

    if ( promoAlreadyApplied ) {
        bootbox.alert({
            className: "bootstrapped cart-modal",
            message: "The promo code is already applied!"
        })

        return false
    }

    const cartMeta = await syncPromoAddition({ giftCode: promoIdent })

    if ( cartMeta ) {
        syncCartMetaIntoStates({ cartMeta })
        const updatedDiscount = getPromoMeta( "appliedGcValue" )

        if ( currentDiscount === updatedDiscount ) {
            bootbox.alert({
                className: "bootstrapped cart-modal",
                message: "The Gift Certificate code is either unrecognized or has been used. Please try again."
            })
        }
        else appliedPromos.push( promoIdent )
    }

    return true
}

async function removePromoFromOrder ({ promoIdent }) {
    const cartMeta = await syncPromoDeletion({ giftCode: promoIdent })

    if ( cartMeta ) {
        syncCartMetaIntoStates({ cartMeta })
        const outdatedCodes = getPromoMeta( "appliedGcCode" )
        patchPromoMeta({
            appliedGcCode: outdatedCodes.filter(
                ( item ) => item !== promoIdent
            )
        })
    }

    return
}

// PUBLIC FUNCTIONS
// --------------------
export async function removePromo ( flaggedPromo ) {
    // id is a uuid and has non-safe JS characters,
    // so ensure its normalized to match up to object keys
    const normalizedIdent = mapServerKeysToUiKeys( flaggedPromo.id )
    const itemKey = `giftCerts.${ normalizedIdent }`
    if ( !promoMeta.has( itemKey ) ) return

    const cartMeta = await syncProductDeletion( flaggedPromo )

    if ( cartMeta ) {
        syncCartMetaIntoStates({ cartMeta })
        removePromoMeta( itemKey )
    }

    return
}


// EXTENDERS
// --------------------



// COMPOSITIONS
// --------------------
export function useApplyPromoCodes () {
    const appliedPromos = computed(
        () => getPromoMeta( "appliedGcCode" )
    )

    return {
        appliedPromos,
        applyPromoToOrder,
        removePromoFromOrder
    }
}
