<script>
import {
    computed,
    defineComponent,
    unref,
    watch
} from "@compose"

import {
    StringType
} from "@PropTypes"


import useAbstract from "@useAbstract"
import { useStepInterface } from "@services/useStepInterface"

import { useCartManagement } from "@cart/useCart"

import { INITIAL_STEP, CHECKOUT_FLOW_SCHEMA } from "./constants"

import PickupControl from "@cart/PickupControl"
import PromoCodeEditor from "@cart/PromoCodeEditor"


export default defineComponent({
    extends: useAbstract,
    components: {
        PickupControl,
        PromoCodeEditor,
    },
    props: {
        init: StringType( INITIAL_STEP )
    },
    setup ( props, ctx ) {
        // COMPOSITIONS / INJECTORS
        // ------------------------
        const {
            canPayTotalWithDiscount,
            cart,
            bypassShippingRequirement,
            grandTotal,
            hasActiveCart,
            hasClasses,
            hasProductQuantities,
            hasProducts,
            hasPromos,
            totalCosts,
            totalProducts,
        } = useCartManagement()

        const {
            currentStep,
            currentStepMeta,
            stepState: checkoutFlowState,
            handleStepAdvance,
            handleStepDecrease,
            loadedComponent,
            patchStepState,
        } = useStepInterface({
            initialStep: props.init,
            stepSchema: CHECKOUT_FLOW_SCHEMA,
        })


        const hasDoubleChargeWarning = computed(
            () => unref( hasProducts )
                && ( unref( hasClasses ) || unref( hasPromos ) )
        )

        watch(
            bypassShippingRequirement,
            ( bypass ) => {
                patchStepState({
                    "payment.nextStepLabel": bypass ? "Proceed to Review" : "Proceed to Shipping Address",
                    "shipping.isBypassed": bypass,
                })
            },
            { immediate: true }
        )

        return {
            bypassShippingRequirement,
            canPayTotalWithDiscount,
            cart,
            checkoutFlowState,
            currentStep,
            currentStepMeta,
            grandTotal,
            handleStepAdvance,
            handleStepDecrease,
            hasActiveCart,
            hasDoubleChargeWarning,
            hasProductQuantities,
            loadedComponent,
            totalCosts,
            totalProducts,
        }
    },
});
</script>



<template lang="pug">
component(
    :append-vefa-style="appendStyleModule($vefa, $style)"
    :is="loadedComponent"
    @next="handleStepAdvance"
)
    template(#header="{ title, showStepBackControl = false, hedStyle = {} }")
        header(
            v-bind="$vefa.header"
        )
            button(
                v-if="showStepBackControl"
                @click="handleStepDecrease"
            ) &lt;

            h1(
                v-bind="hedStyle"
                v-text="title"
            )

    template(#pickup-control)
        pickup-control(
            :append-vefa-style="$vefa.cartMetaControls"
        )

    template(#promo-codes)
        promo-code-editor(
            :append-vefa-style="$vefa.cartMetaControls"
        )

    template(#grand-total)
        dl(
            o-base="iflex:h--p:start--s:base rhy:xStart150 iso:xStartAuto"
        )
            dt(
                class="type:major color:shade"
                o-economy="type:subhed"
            ) Grand Total

            dd(
                class="type:base color:shade"
                o-economy="type:major"
            ) {{ grandTotal }}

        p(
            v-bind="$vefa.chargeMessage"
            v-if="hasDoubleChargeWarning"
        ) You will see two separate charges on your credit&nbsp;card&nbsp;statement.


    template(#step-control="{ stepControlStyle, isDisabled, type = 'button', canProceed = true }")
        button(
            v-bind="stepControlStyle || $vefa.stepControl"
            :disabled="!hasProductQuantities || isDisabled"
            :type="type"
            @click="canProceed ? handleStepAdvance() : () => ({})"
        )
            span {{ currentStepMeta.nextStepLabel || "Continue" }}
</template>



<vefa>
element:
    o-base: flex:v pad:yEq200
    o-condensed: pad:xEq100
    o-economy: grid:content pad:xEq0

header:
    class: type:subhed--face:hed color:dark
    o-base: flex:h--p:start--s:start rhy:xStart50 iso:yEnd100

body:
    o-base: rhy:yStart100
    o-economy: rhy:yStart300

footer:
    o-base: flex:v--p:start--s:start pad:xEq100 iso:yStart300
    o-extended: flex:v--p:start--s:end iso:yStart300 rhy:yStart100

metaMessage:
    o-base: pad:xEq100 pad:yEq25

sectionHed:
    class: type:minor--case:upper color:primary edge:yEnd
    o-base: pad:xEq100 pad:yEq25

cartCosts:
    element:
        o-base: flex:v--p:end pad:xEq100

cartItem:
    class: color:primary edge:yEnd

cartMetaControls:
    element:
        o-base: flex:v--s:end pad:xEq100

chargeMessage:
    class: type:chrome--align:end
    o-base: iso:xStartAuto iso:yEnd200
    o-economy: type:base iso:yEnd0

stepControl:
    class: control:primary type--weight:700 edge:xyEq fx:shift control--h:bright edge--shift:drop50
    o-base: module:static pad:xEq200 pad:yEq50 iso:xEqAuto
    o-condensed: iso:xEnd0


field:
    o-base: module:static pad:xEq100

input:
    class: shade:edge:yEnd  color:primary
    o-base: pad:yEq50 aspect:xEq100--pct
    o-enhanced: aspect:xEq50--view
    o-extended: aspect:xEq25--view

select:
    element:
        class: shade:edge:xyEq--set:radiiRounded color:primary optics:xyEq--set:hidden
        o-base: module:static flex:h--p:start--s:middle aspect:xEq100--pct
        o-enhanced: aspect:xEq35--view
        o-extended: aspect:xEq25--view
        style:
            --radii: 16px

    control:
        o-base: flex:v--p:start--s:start pad:xEq100 pad:yEq25
        o-extended: module:static--self:yMiddle

    icon:
        o-base: aspect:xyEq100--text put:xEnd--set:abs iso:xEnd50
        # o-extended: aspect:xyEq150--text
number:
    class: type--align:middle shade:edge:yEnd color:primary
    o-base: pad:yEq50

label:
    class: optics:a11y
</vefa>



<style lang="stylus">
.cart-modal
    position: absolute
    z-index: 500
    top: 25vw
    margin:auto
    left: 0
    right: 0
</style>



<style lang="stylus" module>
.element
    +o-economy()
        --rows: repeat(4, auto)
        --iso-xEq: ratio(1)
        --iso-yStart: ratio(2)
        --iso-yEnd: ratio(4)
        grid-template-areas: unquote('
            ". . ."
            ". header ."
            ". body ."
            ". footer ."
            ". . ."
        ')

    +o-extended()
        --columns: minmax(ratio(65), 1fr)
        --iso-xEq: minmax(ratio(5), 15vw)

.header
    grid-area: header

.body
    grid-area: body

.footer
    grid-area: footer
</style>
