<script>
import {
    computed,
    defineComponent,
    nextTick,
    ref,
    unref
} from "@compose"

import useAbstract from "@useAbstract"

import { useApplyPromoCodes } from "@cart/useCart"

const SINGLE_PROMO_PLACEHOLDER = "Enter Gift Certificate, Make-up or Discount Code"
const MORE_PROMO_PLACEHOLDER = "Enter Another Code"

export default defineComponent({
    extends: useAbstract,
    props: {
    },
    setup () {
        // COMPOSITIONS / INJECTORS
        // --------------------
        const {
            appliedPromos,
            applyPromoToOrder,
            removePromoFromOrder
        } = useApplyPromoCodes()



        // DATA MODEL / STATE
        // --------------------
        const activeCodeEntryState = ref( false )
        const codeEntryInput = ref( null )
        const currentCode = ref( null )

        const placeholder = computed(
            () => ( unref( appliedPromos ).length
                ? MORE_PROMO_PLACEHOLDER
                : SINGLE_PROMO_PLACEHOLDER )
        )

        // HANDLERS
        // --------------------
        function handleActivateCodeEntry ({ state = !unref( activeCodeEntryState ) }) {
            activeCodeEntryState.value = state

            state && nextTick(
                () => {
                    unref( codeEntryInput ).focus()
                }
            )
        }


        async function handleAdd () {
            handleActivateCodeEntry({ state: false })

            if ( !unref( currentCode ) ) return

            await applyPromoToOrder({ promoIdent: unref( currentCode ) })

            currentCode.value = null

            return
        }



        async function handleRemove ( promoIdent ) {
            if ( !promoIdent ) return

            return await removePromoFromOrder({ promoIdent })
        }


        // PROVIDERS
        // --------------------
        return {
            activeCodeEntryState,
            appliedPromos,
            codeEntryInput,
            currentCode,
            handleActivateCodeEntry,
            handleAdd,
            handleRemove,
            placeholder,
        }
    }
});
</script>



<template lang="pug">
form(
    v-bind="$vefa.element"
    @submit.prevent="handleAdd"
)

    template(v-for="promo in appliedPromos")
        .PromoCodes-promo(
            v-bind="$vefa.item"
        )
            span(
                v-bind="$vefa.promoInput"
            ) {{ promo }}

            button(
                type="button"
                v-bind="$vefa.removeControl"
            )
                trash-icon(
                    v-bind="$vefa.removeControlIcon"
                    @click="handleRemove(promo)"
                )

    .PromoCodes-promo(
        v-bind="$vefa.item"
    )
        input(
            ref="codeEntryInput"
            v-bind="$vefa.promoInput"
            v-model="currentCode"
            v-show="activeCodeEntryState"
            :placeholder="placeholder"
        )

        label(
            v-bind="$vefa.promoLabel"
            v-if="!activeCodeEntryState"
            v-html="placeholder"
            @click="handleActivateCodeEntry"
        )

        //- button(
        //-     type="button"
        //-     v-bind="$vefa.addControl"
        //-     @click="handleAdd"
        //- )
        //-     span +

    small(
        v-bind="$vefa.metaText"
    )
        template(v-if="currentCode && currentCode.length") Pressing enter will apply your gift code.
</template>


<vefa>
element:
    o-base: flex:v--p:start pad:yEq100 rhy:yStart25

item:
    class: type:chrome--align:start edge:xyEq
    o-base: module:static flex:h--p:start--s:middle pad:xyEq50
    o-condensed: type:minor flex:h--p:middle--s:middle aspect:xEq60--pct
    o-economy: aspect:xEq50--pct

promoLabel:
    class: type--align:start

promoInput:
    class: PromoCodes-input color:primary
    o-base: aspect:xEq100--pct
    o-condensed: type:minor--align:middle

addControl:
    class: PromoCodes-control--add type:subhed--lead:flush color:primary
    o-base: put:xEnd--set:abs adj:yEnd10 iso:xEnd50

removeControl:
    class: color:shade
    o-base: iso:xStartAuto

removeControlIcon:
    o-base: aspect:xyEq100--text

metaText:
    class: type:minor--weight:700 color:shade
</vefa>



<style lang="stylus">
.PromoCodes-input
    &:focus
        outline: 0

    &:not(:focus)::placeholder
        color: currentColor

.PromoCodes-promo
    max-width: 400px

.PromoCodes-control--add
    right: 0

    +o-condensed()
        right: ratio(2)
</style>
