// useLocalStorage
// ------------------------
// IMPORTS
// DATA MODELS / STATE
// PRIVATE FUNCTIONS
// PUBLIC FUNCTIONS
// EXTENDERS
// COMPOSITIONS



// IMPORTS
// ------------------------
import {
    unref,
    watch
} from "@compose"

import { useLocalStorage as useLS } from "vue-composable"


// DATA MODELS / STATE
// ------------------------



// PRIVATE FUNCTIONS
// ------------------------



// PUBLIC FUNCTIONS
// ------------------------
export function setStorage ({ state }) {
    return Object.entries( state )
        .reduce(
            ( storage, [ key, value ]) => {
                storage[key] = value

                return storage
            },
            {}
        )
}

// EXTENDERS
// ------------------------



// COMPOSITIONS
// ------------------------
export function useStorage ( props ) {
    const {
        keepUpdated = false,
        patch = () => ({}),
        state,
        storageName,
    } = props

    const {
        remove: removeStoredData,
        storage: storedData
    } = localStorage.getItem( storageName )
        // if storageName exists, return the data
        ? useLS( storageName )
        // else set current state to the storageNAme
        : useLS(
            storageName,
            setStorage({ state })
        )


    // sync state on load
    patch(
        unref( storedData )
    )


    if ( keepUpdated ) {
        watch(
            () => state,
            ( state ) => {
                storedData.value = setStorage({ state })
            },
            { deep: true }
        )
    }

    return {
        remove: removeStoredData,
        set ( value ) {
            storedData.value = value
        },
        storage: storedData
    }
}
