// defineOrderProcessing
// ------------------------
// IMPORTS
// DATA MODELS / STATE
// PRIVATE FUNCTIONS
// PUBLIC FUNCTIONS
// EXTENDERS
// COMPOSITIONS

// checkout_form["payment-cc_number"] = "5365701118649681"  # Fake
// checkout_form["payment-cc_exp_month"] = "02"
// checkout_form["payment-cc_exp_year"] = VALID_CC_YEAR
// checkout_form["payment-cc_vv"] = "098"

// IMPORTS
// ------------------------
import {
    computed,
    unref
} from "@compose"

import {
    submitOrder
} from "./defineSyncing"

import {
    getAppliedCodes
} from "./definePromoManagement"

import {
    getCC,
    getBilling,
    getShipping
} from "./defineCustomerManagement"

// DATA MODELS / STATE
// ------------------------
const orderPayload = computed(
    () => ({
        ...getPersonalInfo(),
        ...getPaymentInfo(),
        ...getBillingInfo(),
        ...getShipppingInfo(),
        ...getAppliedGiftCodes()
    })
)


// PRIVATE FUNCTIONS
// ------------------------
function packageData ({ currentData, schema }) {
    return Object.entries( schema )
        .reduce(
            ( packagedData, [ key, value ]) => {
                packagedData[key] = currentData[value] || ""

                return packagedData
            },
            {}
        )
}

function getPaymentInfo () {
    const schema = {
        "payment-cc_exp_month": "expirationMonth",
        "payment-cc_exp_year": "expirationYear",
        "payment-cc_number": "number",
        "payment-cc_vv": "cvv",
    }

    const currentData = getCC()

    return packageData({
        currentData,
        schema,
    })
}

function getPersonalInfo () {
    const schema = {
        email: "email",
        name: "name",
        optin_newsletter: "newsletter",
        phone: "phone",
    }

    const currentData = {
        ...getBilling(),
        newsletter: "n" // no design for newsletter
    }

    return packageData({
        currentData,
        schema,
    })
}

function getBillingInfo () {
    const schema = {
        "billing_address-address_line": "street",
        "billing_address-address_name": "name",
        "billing_address-city": "city",
        "billing_address-state": "state",
        "billing_address-zip_code": "zipcode",
    }

    const currentData = {
        name: getBilling( "name" ),
        ...getBilling( "address" ),
    }


    return packageData({
        currentData,
        schema,
    })
}

function getAppliedGiftCodes () {
    const currentData = getAppliedCodes()

    return currentData
        .reduce(
            ( packagedData, code, index ) => {
                packagedData[`gift_certificate_code-${ index }`] = code

                return packagedData
            },
            {
                "gift_certificate_code-0": ""
            }
        )
}

function getShipppingInfo () {
    const schema = {
        "shipping_address-address_line": "street",
        "shipping_address-address_name": "name",
        "shipping_address-city": "city",
        "shipping_address-state": "state",
        "shipping_address-zip_code": "zipcode",
    }

    const currentData = {
        name: getShipping( "name" ),
        ...getShipping( "address" ),
    }

    return packageData({
        currentData,
        schema,
    })
}


// PUBLIC FUNCTIONS
// ------------------------
async function processCart () {
    const {
        status
    } = await submitOrder( unref( orderPayload ) )

    // 200 means success in processing
    // 400 means error in processing
    // additional data not used at this time
    return ( status === 200 )
}


// EXTENDERS
// ------------------------



// COMPOSITIONS
// ------------------------
export function useOrderProcessing () {
    return {
        orderPayload,
        processCart
    }
}
